/**
 * Core
 */
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'
import Mailto from '../components/mailto'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


const TermsAndConditions = () => {

    useScrollTrigger('.reveal')

    
    return (
        <>
            <section className='bg-white py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Terms and Conditions</h1>
                </div>
            </section>
            <section className="bg-gradient-pastel text-ai-gray-900 standard-text pt-16">
                <div className="reveal top">
                    <div className="">
                        <p>These Terms and Conditions, together with any and all other documents referred to herein, set out the terms of use under which you may use any of the websites operated by Awards International anywhere in the world (“Our Sites”).</p>
                        <p>Please read these Terms and Conditions carefully and ensure that you understand them.</p>
                        <p>Your agreement to comply with and be bound by these Terms and Conditions is deemed to occur upon your first use of Our Sites. If you do not agree to comply with and be bound by these Terms and Conditions, you must stop using Our Sites immediately.</p>
                    </div>
                    {/* <div className="">
                         <div className='font-semibold text-2xl pb-4'>TABLE OF CONTENTS</div>
                        <ol className='font-semibold table-of-contents'>
                            <li><a href='#Our services'>OUR SERVICES</a></li>
                            <li><a href='#Intellectual property rights'>INTELLECTUAL PROPERTY RIGHTS</a></li>
                            <li><a href='#User representations'>USER REPRESENTATIONS</a></li>
                            <li><a href='#Prohibited activities'>PROHIBITED ACTIVITIES</a></li>
                            <li><a href='#User generated contributions'>USER GENERATED CONTRIBUTIONS</a></li>
                            <li><a href='#Contribution licence'>CONTRIBUTION LICENCE</a></li>
                            <li><a href='#Services management'>SERVICES MANAGEMENT</a></li>
                            <li><a href='#Privacy policy'>PRIVACY POLICY</a></li>
                            <li><a href='#Term and termination'>TERM AND TERMINATION</a></li>
                            <li><a href='#Modifications and interruptions'>MODIFICATIONS AND INTERRUPTIONS</a></li>
                            <li><a href='#Governing law'>GOVERNING LAW</a></li>
                            <li><a href='#Dispute resolution'>DISPUTE RESOLUTION</a></li>
                            <li><a href='#Corrections'>CORRECTIONS</a></li>
                            <li><a href='#Disclaimer'>DISCLAIMER</a></li>
                            <li><a href='#Limitations of liability'>LIMITATIONS OF LIABILITY</a></li>
                            <li><a href='#Indemnification'>INDEMNIFICATION</a></li>
                            <li><a href='#User data'>USER DATA</a></li>
                            <li><a href='#Electronic communications, transactions and signatures'>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</a></li>
                            <li><a href='#California users and residents'>CALIFORNIA USERS AND RESIDENTS</a></li>
                            <li><a href='#Miscellaneous '>MISCELLANEOUS</a></li>
                            <li><a href='#Contact us'>CONTACT US</a></li>
                        </ol> 
                    </div> */}
                        <div id="" className='scroll-mt-24'>
                            <h5>1. Definitions and Interpretation</h5>

                            <ol className='!pl-8 !mb-5'>
                                <li><b>1.1.</b> In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</li>
                                <li><b>1.2.</b> “Content” means any and all text, images, audio, video, scripts, code, software, databases and any other form of information capable of being stored on a computer that appears on, or forms part of, Our Sites; and “We/Us/Our” means Awards International [, a company registered in England under company number 6707388, whose registered address is Acacia Farm, Lower Road, Royston Hertfordshire SG8 0EE.</li>
                            </ol>

                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>2. Information About Us</h5>

                            <ol className='!pl-8 !mb-5'>
                                <li><b>2.1.</b> Our Sites, are owned and operated by Awards International, a limited company registered in England under company number 6707388, whose registered address is Acacia Farm, Lower Road, Royston Hertfordshire SG8 0EE.</li>
                            </ol>
                        </div>
                        <div id="" className='scroll-mt-24'>

                            <h5>3. Access to Our Sites</h5>
                            <ol className='!pl-8 !mb-5'>
                                <li><b>3.1.</b> Access to Our Sites is free of charge.</li>
                                <li><b>3.2.</b> It is your responsibility to make any and all arrangements necessary in order to access Our Sites.</li>
                                <li><b>3.3.</b> Access to Our Sites is provided “as is” and on an “as available” basis. We may alter, suspend or discontinue Our Sites (or any part of it) at any time and without notice. We will not be liable to you in any way if Our Sites (or any part of it) is unavailable at any time and for any period.</li>
                            </ol>
                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>4. Intellectual Property Rights</h5>
                            <ol className='!pl-8 !mb-5'>
                                <li><b>4.1.</b> All Content included on Our Sites and the copyright and other intellectual property rights subsisting in that Content, unless specifically labelled otherwise, belongs to or has been licensed by Us. All Content is protected by applicable United Kingdom and international intellectual property laws and treaties.</li>
                                <li><b>4.2.</b> Subject to sub-Clause[s] 4.3 [and 4.6] you may not reproduce, copy, distribute, sell, rent, sub-license, store, or in any other manner re-use Content from Our Sites unless given express written permission to do so by Us.</li>
                                <li>
                                    <b>4.3.</b> You may:
                                    <ol className='!pl-10'>
                                        <li><b>4.3.1</b> Access, view and use Our Sites in a web browser (including any web browsing capability built into other types of software or app);</li>
                                        <li><b>4.3.2</b> Download Our Sites (or any part of it) for caching;</li>
                                        <li><b>4.3.3</b> Print one copy of any page from Our Sites;</li>
                                        <li><b>4.3.4</b> Download extracts from pages on Our Sites; and</li>
                                        <li><b>4.3.5</b> Save pages from Our Sites for later and/or offline viewing.</li>
                                    </ol>
                                </li>
                                <li><b>4.4.</b> Our status as the owner and author of the Content on Our Sites (or that of identified licensors, as appropriate) must always be acknowledged.</li>
                                <li><b>4.5.</b> You may not use any Content saved or downloaded from Our Sites for commercial purposes without first obtaining a license from Us to do so.</li>
                                <li><b>4.6.</b> Nothing in these Terms and Conditions limits or excludes the provisions of Chapter III of the Copyrights, Designs and Patents Act 1988 ‘Acts Permitted in Relation to Copyright Works’, covering in particular the making of temporary copies; research and private study; the making of copies for text and data analysis for non- commercial research; criticism, review, quotation and news reporting; caricature, parody or pastiche; and the incidental inclusion of copyright material.</li>
                            </ol>
                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>5. Links to Our Sites</h5>
                            <ol className='!pl-8 !mb-5'>
                                <li><b>5.1.</b> You may link to Our Sites provided that:</li>
                                <li><b>5.2.</b> You do so in a fair and legal manner;</li>
                                <li><b>5.3.</b> You do not do so in a manner that suggests any form of association, endorsement or approval on Our part where none exists;</li>
                                <li><b>5.4.</b> You do not use any logos or trademarks displayed on Our Sites without Our express written permission; and</li>
                                <li><b>5.5.</b> You do not do so in a way that is calculated to damage Our reputation or to take unfair advantage of it.</li>
                                <li><b>5.6.</b> You may not link to any page other than the homepage of Our Sites. Deep-linking to other pages requires Our express written permission.</li>
                                <li><b>5.7.</b> Framing or embedding of Our Sites on other websites is not permitted without Our express</li>
                                <li><b>5.8.</b> Written permission. Please contact Us at <Mailto email='accounts@awardsinternational.com' className='mailto font-semibold'>accounts@awardsinternational.com</Mailto> for further information.</li>
                                <li>
                                    <b>5.9.</b> In any event you may not link to Our Sites from any other site the main content of which contains material that:
                                    <ol className='!pl-10'>
                                        <li><b>5.9.1</b> is sexually explicit;</li>
                                        <li><b>5.9.2</b> is obscene, deliberately offensive, hateful or otherwise inflammatory;</li>
                                        <li><b>5.9.3</b> promotes violence;</li>
                                        <li><b>5.9.4</b> promotes or assists in any form of unlawful activity;</li>
                                        <li><b>5.9.5</b> discriminates against, or is in any way defamatory of, any person, group or class of persons, race, sex, religion, nationality, disability, sexual orientation, or age;</li>
                                        <li><b>5.9.6</b> is intended or is otherwise likely to threaten, harass, annoy, alarm, inconvenience, upset, or embarrass another person;</li>
                                        <li><b>5.9.7</b> is calculated or is otherwise likely to deceive another person;</li>
                                        <li><b>5.9.8</b> is intended or otherwise likely to infringe (or threaten to infringe) another
                                        person’s right to privacy;</li>
                                        <li><b>5.9.9</b> misleadingly impersonates any person or otherwise misrepresents the identity or affiliation of a particular person in a way that is calculated to deceive (obvious parodies are not included in this definition provided that they do not fall within any of the other provisions of this sub-Clause 5.4);</li>
                                        <li><b>5.9.10</b> implies any form of affiliation with Us where none exists;</li>
                                        <li><b>5.9.11</b> infringes, or assists in the infringement of, the intellectual property rights (including, but not limited to, copyright, trademarks and database rights) of any other party; or</li>
                                        <li><b>5.9.12</b> is made in breach of any legal duty owed to a third party including, but not limited to, contractual duties and duties of confidence.</li>
                                    </ol>
                                </li>
                                <li><b>5.10.</b> The content restrictions in sub-Clause 5.4 do not apply to content submitted to sites by other users provided that the primary purpose of the site accords with the provisions of sub-Clause 5.4. You are not, for example, prohibited from posting links on general-purpose social networking sites merely because another user may post such content. You are, however, prohibited from posting links on websites which focus on or encourage the submission of such content from users.</li>
                            </ol>
                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>6. Links to Other Sites</h5>
                            <p>Links to other sites may be included on Our Sites. Unless expressly stated, these sites are not under Our control. We neither assume nor accept responsibility or liability for the content of third-party sites. The inclusion of a link to another site on Our Sites is for information only and does not imply any endorsement of the sites themselves or of those in control of them.</p>
                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>7. Disclaimers</h5>
                            <ol className='!pl-8 !mb-5'>
                                <li><b>7.1.</b> Nothing on Our Sites constitutes advice on which you should rely. It is provided for general information purposes only</li>
                                <li><b>7.2.</b> Insofar as is permitted by law, We make no representation, warranty, or guarantee that Our Sites will meet your requirements, that it will not infringe the rights of third parties, that it will be compatible with all software and hardware, or that it will be secure.</li>
                                <li><b>7.3.</b> We make reasonable efforts to ensure that the Content on Our Sites is complete, accurate, and up-to-date. We do not, however, make any representations, warranties or guarantees (whether express or implied) that the Content is complete, accurate, or up-to-date.</li>
                            </ol>
                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>8. Our Liability</h5>
                            <ol className='!pl-8 !mb-5'>
                                <li><b>8.1.</b> To the fullest extent permissible by law, We accept no liability to any user for any loss or damage, whether foreseeable or otherwise, in contract, tort (including negligence), for breach of statutory duty, or otherwise, arising out of or in connection with the use of (or inability to use) Our Sites or the use of or reliance upon any Content included on Our Sites.</li>
                                <li><b>8.2.</b> To the fullest extent permissible by law, We exclude all representations, warranties, and guarantees (whether express or implied) that may apply to Our Sites or any Content included on Our Sites.</li>
                                <li><b>8.3.</b> If you are a business user, We accept no liability for loss of profits, sales, business or revenue; loss of business opportunity, goodwill or reputation; loss of anticipated savings; business interruption; or for any indirect or consequential loss or damage.</li>
                                <li><b>8.4.</b> We exercise all reasonable skill and care to ensure that Our Sites are free from viruses and other malware. We accept no liability for any loss or damage resulting from a virus or other malware, a distributed denial of service attack, or other harmful material or event that may adversely affect your hardware, software, data or other material that occurs as a result of your use of Our Sites (including the downloading of any Content from it) or any other site referred to on Our Sites.</li>
                                <li><b>8.5.</b> We neither assume nor accept responsibility or liability arising out of any disruption or non-availability of Our Sites resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, natural events, acts of war, or legal restrictions and censorship.</li>
                                <li><b>8.6.</b> Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or fraudulent misrepresentation, for death or personal injury resulting from negligence, or for any other forms of liability which cannot be excluded or restricted by law. For full details of consumers’ legal rights, including those relating to digital content, please contact your local Citizens’ Advice Bureau or Trading Standards Office.</li>
                            </ol>
                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>9. Viruses, Malware and Security</h5>
                            <ol className='!pl-8 !mb-5'>
                                <li><b>9.1.</b> We exercise all reasonable skill and care to ensure that Our Sites are secure and free from viruses and other malware</li>
                                <li><b>9.2.</b> You are responsible for protecting your hardware, software, data and other material from viruses, malware, and other internet security risks.</li>
                                <li><b>9.3.</b> You must not deliberately introduce viruses or other malware, or any other material which is malicious or technologically harmful either to or via Our Sites.</li>
                                <li><b>9.4.</b>You must not attempt to gain unauthorised access to any part of Our Sites, the server on which Our Sites is stored, or any other server, computer, or database connected to Our Sites.</li>
                                <li><b>9.5.</b> You must not attack Our Sites by means of a denial of service attack, a distributed denial of service attack, or by any other means.</li>
                                <li><b>9.6.</b> By breaching the provisions of sub-Clauses 9.3 to 9.5 you may be committing a criminal offence under the Computer Misuse Act 1990. Any and all such breaches will be reported to the relevant law enforcement authorities and We will cooperate fully with those authorities by disclosing your identity to them. Your right to use Our Sites will cease immediately in the event of such a breach.</li>
                            </ol>

                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>10. Acceptable Usage Policy</h5>
                            <ol className='!pl-8 !mb-5'>
                                <li>
                                    <b>10.1.</b> You may only use Our Sites in a manner that is lawful. Specifically:
                                    <ol className='!pl-10'>
                                        <li><b>10.1.1</b> you must ensure that you comply fully with any and all local, national or international laws and/or regulations;</li>
                                        <li><b>10.1.2</b> you must not use Our Sites in any way, or for any purpose, that is unlawful or fraudulent;</li>
                                        <li><b>10.1.3</b> you must not use Our Sites to knowingly send, upload, or in any other way transmit data that contains any form of virus or other malware, or any other code designed to adversely affect computer hardware, software, or data of any kind; and</li>
                                        <li><b>10.1.4</b> you must not use Our Sites in any way, or for any purpose, that is intended to harm any person or persons in any way.</li>
                                    </ol>
                                </li>
                                <li>
                                    <b>10.2.</b> We reserve the right to suspend or terminate your access to Our Sites if you materially breach the provisions of this Clause 10 or any of the other provisions of these Terms and Conditions. Specifically, We may take one or more of the following actions:
                                    <ol className='!pl-10'>
                                        <li><b>10.2.1</b> suspend, whether temporarily or permanently, your right to access Our Sites;</li>
                                        <li><b>10.2.2</b> issue you with a written warning;</li>
                                        <li><b>10.2.3</b> take legal proceedings against you for reimbursement of any and all relevant costs on an indemnity basis resulting from your breach;</li>
                                        <li><b>10.2.4</b> take further legal action against you as appropriate;</li>
                                        <li><b>10.2.5</b> disclose such information to law enforcement authorities as required or as We deem reasonably necessary; and/or</li>
                                        <li><b>10.2.6</b> any other actions which We deem reasonably appropriate (and lawful).</li>
                                    </ol>
                                </li>
                                <li><b>10.3.</b> We hereby exclude any and all liability arising out of any actions (including, but not limited to those set out above) that We may take in response to breaches of these Terms and Conditions.</li>
                            </ol>

                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>11. Privacy and Cookies</h5>
                            <p className='!mb-5'>Use of Our Sites is also governed by Our Privacy and Cookies Policy, available from <Link to='https://awardsinternational.com/privacy-policy' className='linkto' target='_blank'>https://awardsinternational.com/privacy-policy</Link>. These policies are incorporated into these Terms and Conditions by this reference.</p>

                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>12. Changes to these Terms and Conditions</h5>
                            <ol className='!pl-5 !mb-5'>
                                <li><b>12.1</b> We may alter these Terms and Conditions at any time. Any such changes will become binding on you upon your first use of Our Sites after the changes have been implemented. You are therefore advised to check this page from time to time.</li>
                                <li><b>12.1</b> In the event of any conflict between the current version of these Terms and Conditions and any previous version(s), the provisions current and in effect shall prevail unless it is expressly stated otherwise.</li>
                            </ol>

                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>13. Contacting Us</h5>
                            <p className='!mb-5'>To contact Us, please email Us at <Mailto email='accounts@awardsinternational.com' className='mailto font-semibold'>accounts@awardsinternational.com</Mailto> or using any of the methods provided on Our contact page one of Our Sites.</p>

                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>14. Communications from Us</h5>
                            <ol className='!pl-5 !mb-5'>
                                <li><b>14.1</b> If We have your contact details, We may from time to time send you important notices by email. Such notices may relate to matters including, but not limited to, service changes and changes to these Terms and Conditions.</li>
                                <li><b>14.2</b> We will never send you marketing emails of any kind without your express consent. If you do give such consent, you may opt out at any time. Any and all marketing emails sent by Us include an unsubscribe link. If you opt out of receiving emails from Us at any time, it may take up to seven business days for Us to comply with your request. During that time, you may continue to receive emails from Us.</li>
                                <li><b>14.3</b> For questions or complaints about communications from Us (including, but not limited to marketing emails), please contact Us at <Mailto email='accounts@awardsinternational.com' className='mailto font-semibold'>accounts@awardsinternational.com</Mailto>.</li>
                            </ol>
                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>15. Data Protection</h5>
                            <ol className='!pl-5 !mb-5'>
                                <li><b>15.1</b> All personal information that We may use will be collected, processed, and held in accordance with the provisions of EU Regulation 2016/679 General Data Protection Regulation (“GDPR”) and your rights under the GDPR.
                                For complete details of Our collection, processing, storage, and retention of personal data including, but not limited to, the purpose(s) for which personal data is used, the legal basis or bases for using it, details of your rights and how to exercise them, and personal data sharing (where applicable), please refer to Our Privacy Policy as mentioned above.</li>
                            </ol>

                        </div>
                        <div id="" className='scroll-mt-24'>
                            <h5>16. Law and Jurisdiction</h5>
                            <ol className='!pl-5 !mb-5'>
                                <li><b>16.1</b> These Terms and Conditions, and the relationship between you and Us (whether contractual or otherwise) shall be governed by, and construed in accordance with the law of England & Wales.</li>
                                <li><b>16.2</b> If you are a consumer, you will benefit from any mandatory provisions of the law in your country of residence. Nothing in Sub-Clause 16.1 above takes away or reduces your rights as a consumer to rely on those provisions.</li>
                                <li><b>16.3</b> If you are a consumer, any dispute, controversy, proceedings or claim between you and Us relating to these Terms and Conditions, or the relationship between you and Us (whether contractual or otherwise) shall be subject to the jurisdiction of the courts of England & Wales.</li>
                                <li><b>16.4</b> If you are a business, any disputes concerning these Terms and Conditions, the relationship between you and Us, or any matters arising therefrom or associated therewith (whether contractual or otherwise) shall be subject to the exclusive jurisdiction of the courts of England & Wales.</li>
                            </ol>

                        </div>
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default TermsAndConditions