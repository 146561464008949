/**
 * Core
 */
import React, { useRef, useEffect, useState } from 'react'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'
import useScrollPosition from '../hooks/scroll-position.hook'

const Step = ({ props }) => {
    const stepRef = useRef(null)
    const markRef = useRef(null)
    const borderRef = useRef(null)
    const { step, last } = props
    const scrollPosition = useScrollPosition(stepRef)

    useEffect(() => {
        let p = 0
        if(scrollPosition.element !== null) {
            if( scrollPosition.element.top >= 0 && ( scrollPosition.innerHeight >= scrollPosition.element.top * 2 ) )
            {
                
                p = (scrollPosition.innerHeight/2 - scrollPosition.element.top) / scrollPosition.element.height * 100
                p = p > 100 ? 100 : Math.floor(p)
            }
            else
            {
                
                if( scrollPosition.element.bottom >= 0) {
                    if( scrollPosition.innerHeight <= scrollPosition.element.bottom * 2 )
                        p = (scrollPosition.innerHeight/2 - scrollPosition.element.top) / scrollPosition.element.height * 100
                    else p = 100
                }
            }
            
            if(!last) markRef.current.classList.add('line')
            else markRef.current.style.background = 'none'
            
            if( p>0 ) markRef.current.classList.add('step-animation')
            else markRef.current.classList.remove('step-animation')
            
        }
        markRef.current.style.height = p + "%"
    }, [scrollPosition])

    return (
        <div className='step relative grid grid-cols-[64px_auto] gap-8' ref={stepRef}>
            <div className='mark' ref={markRef}>
                <span ref={borderRef}>{step.mark}</span>
            </div>
            <div className='content'>
                <div className='font-semibold text-2xl'>{step.title}</div>
                <div className='text-xl'>{step.date}</div>
                <p className='pt-0' dangerouslySetInnerHTML={{ __html: step.content }}></p>
            </div>
        </div>
    )
}

const VerticalStepper = ({ props }) => {
    const stepperRef = useRef(null)
    const scrollPosition = useScrollPosition(stepperRef)

    useEffect(() => {
        //console.log(scrollPosition)
    }, [scrollPosition])

    return (
        props.hasOwnProperty('steps') && Array.isArray(props.steps)
            ? 
                <div className='stepper' ref={stepperRef}>
                    { props.steps.map((step, index) => <Step props={{ step, last: props.steps.length == index + 1 ? true : false }} key={index} /> ) }
                </div>
            :
                <></>
    )
}

export default VerticalStepper