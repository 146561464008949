/**
 * Components
 */
import Footer from '../components/footer'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


const TermsOfPayment = () => {

    useScrollTrigger('.reveal')

    
    return (
        <>
            <section className='bg-white py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Terms of Payment</h1>
                </div>
            </section>
            <section className="bg-gradient-pastel text-ai-gray-900 standard-text pt-16">
                <div className="reveal top">
                    <div className="">
                        <h5>Definitions:</h5>
                        <p>The following terms shall have the following meaning throughout these terms of payment: ‘Events’ means any and all awards programmes or events operated by Awards International anywhere in the world on any medium including video conference software.</p>
                        <p>‘Services’ means those services which are provided by Awards International or its suppliers to provide the Events and generally refers to the processes, web and personal services for participation in the Events.</p>
                        <p>‘Venue’ means the location of the provision of the services and includes online video conference software.</p>
                        <p>‘Customer’ means the person or company whose name appears on the appropriate forms required for making a purchase.</p>
                        <p>‘Company’ means: Awards International Ltd, whose registered office is at Acacia Farm, Lower Road, Royston SG8 0EE. Company number 5638188 a company registered in England and Wales.</p>
                        <p>‘Parties’ means the customer and the company when referred to jointly.</p>
                        <p>The following terms of payment refer to and govern the purchase of Awards International services.</p>
                        <p>Please read these Terms of Payment carefully, because by using the Services you accept and agree to be bound and abide by these Terms of Payment.</p>
                        <p>Customers acknowledge and agree that it is their responsibility to review these Terms of Payment from time to time and to familiarise themselves with any modifications.</p>
                        <p>Customers’ continued use of this Website after such modifications will constitute acknowledgement of the modified Terms of Payment and agreement to abide and be bound by the modified Terms of Payment.</p>
                        <p>If there is any agreement between the parties to vary these Terms of Payment the Parties will do so in writing via email or post.</p>
                        <p>Information supplied by the Company in relation to any activity is accurate to the best of its knowledge and belief but shall not constitute any warranty or representation by the Company.</p>
                        <p>We expressly reserve the right to change these Terms of Payment from time to time without notice.</p>
                        <br/>
                        <h5>The Company Agrees</h5>
                        <p>To provide the Events and Services and to ensure the Customer benefits from doing so to the best of its ability and in good faith.</p>
                        <p>To provide the Customer with the forms and instructions to enable them to enroll in the Events according to the purpose of the Event.</p>
                        <p>To provide the Customer appropriate support and opportunities to communicate with the Company in the event that the Customer wishes to do so.</p>
                        <p>To seek feedback from Customers to improve the Events and Services to the best of their ability.</p>
                        <br/>
                        <h5>The Customer Agrees</h5>
                        <p>That they wish to participate in the Events and wish to benefit from the Services provided to the Customer in the manner that the Company determines. </p>
                        <p>To comply with the rules or guidelines as provided by the Company from time to time to enable them to participate in the awards and benefit from the Services.</p>
                        <p>That they are authorised to apply for the Services.</p>
                        <p>To pay for the services as per the Terms of Payment set out herein and in particular the Payment Terms section below.</p>
                        <br/>
                        <h5>Confidentiality</h5>
                        <p>All information submitted by the Customer to the Company will be treated with the strictest confidence.</p>
                        <p>Any information which is readily available in the public domain or becomes available in the public domain after the submission of such information will be exempt from the above.</p>
                        <p>The Company reserves the right to share the content of the Customer’s entry, that the Customer has provided to us through the use of our Awards Manager platform, to third parties if The Customer has provided us with a special consent to do so for the purposes of art. 6. (1) lit. f of the General Data Protection Regulation.</p>
                        <p>In such cases that the Customer has have provided the Company with the special consent the information provided on the Customer’s entry form will be shared with our Customers in the form of brochures, websites, networking meetings, social media, for the purpose of helping businesses learn and improve.</p>
                        <p>No information will be provided to third parties not involved in the running of the Events without the written consent of the Customer, apart from exceptions further provided in these Terms of Payment.</p>
                        <p>To ensure that all records of Customer information are retained securely and kept strictly confidential and in accordance with the data protection act, in force at the time.</p>
                        <br/>
                        <h5>True and Accurate Representation</h5>
                        <p>All information provided by the Customer in applying for an event or for the services is true and accurate to the best of their knowledge.</p>
                        <p>The Customer confirms they are entitled to submit such information as is required and in the event that they are an agent or a representative of third parties they will indemnify the Company from any complaint arising from any misrepresentation.</p>
                        <br/>
                        <h5>Use of Data for Academic Purposes</h5>
                        <p>The Customer agrees that the Company may use the information provided in the entry form for the creation of academic research or the commercialisation of the same.</p>
                        <p>In the event that a third party is required to assist the Company in production of such work, the Company will take steps to ensure the security and confidentiality of the data is maintained.</p>
                        <p>If in any doubt with regard to any matters of confidentiality or sensitivity with regard to any information the Company will contact the Customer and request permission or guidance before taking further steps.</p>
                        <p>Payment Terms</p>
                        <br/>
                        <h5>Online Payment</h5>
                        <p>Customers may purchase services through our website using a debit or credit card. The Company does not impose additional fees for card payments. A VAT receipt will be issued upon receipt of payment.</p>
                        <br/>
                        <h5>Invoice Payment</h5>
                        <p>Payment by invoice is available only by prior agreement with the Company. There are no credit terms, and payment must be made immediately or within a maximum of 30 days from the invoice date, or before the final deadline for discounted pricing (Super Early Bird or Early Bird), whichever comes first.</p>
                        <p>An acceptable Purchase Order (PO) must be received for payment by invoice. The issuance of a PO number alone is not sufficient; the Company requires its own Purchase Order to be completed. The Company is not obligated to accept any Purchase Order or its terms unless explicitly agreed upon.</p>
                        <p>If a Customer’s accounts payable department requires a PO before approving payment, this does not affect the invoice due date, which remains based on the date the Company issues the invoice.</p>
                        <p>Partial payments are not permitted; the full invoice amount must be settled in a single transaction. Additionally, all bank charges related to the transaction must be fully covered by the remitter (Customer).</p>
                        <p>The Company reserves the right to withhold services until full payment is received.</p>
                        <br/>
                        <h5>Refund Policy</h5>
                        <p>No refunds will be issued 14 days after the payment date or within 30 days of the closing deadline for the purchased service, whichever comes first.  Due to Payment Service Provider (PSP) fees not being refunded to the Company, the Company will not refund the PSP credit card fee portion, which is normally approximately 3.9% of the gross sales value.</p>
                        <p>Cancellation of an invoice supported by a signed Purchase Order will not be accepted.</p>
                        <p>The Company may choose to offer a Customer an alternative option at its sole discretion.</p>
                        <p>This means that the Company will continue to collect payment whether the Customer participates in its Events or not.</p>
                        <p>The Customer agrees to pay the price for the Services as advertised on the appropriate Event web page or web site.</p>
                        <p>In the event that the Customer does not comply with these Payment Terms, the Company reserves the right to withhold the Services. This may mean but may not be limited to the following:</p>
                        <p>In the case of Awards, the Customer may not be considered as a finalist or winner.</p>
                        <p>The status of winner or finalist may be withdrawn such information may be communicated publicly in a correction statement.</p>
                        <p>The Customer may not have their profile listed on the website nor use the assets for the awards for publicity.</p>
                        <p>The Customer or their employees or guests may not be able to attend the Event.</p>
                        <p>The Customer acknowledges that failure to submit forms, such as an entry form or booking information requested according to the rules and guidelines does not cancel the agreement between the Parties.</p>
                        <br/>
                        <h5>Late Payment</h5>
                        <p>After 7 days of the due date of the invoice interest will be added to the balance owing at the rate of 2.2% per month in accordance with the late payment of commercial debts act 1998. A £40 administration charge will also be added to the balance outstanding.</p>
                        <p>For each reminder to pay the Company will add £40 to the balance outstanding.</p>
                        <p>In the event that more than two reminders have been sent, and in the absence of payment the Company will immediately apply to the county court for a judgement and will not issue any further notice.</p>
                        <br/>
                        <h5>The Entry Fee (Awards)</h5>
                        <p>The Company charges an Entry Fee for Awards participation. This fee covers access to the online entry form, administration of the entry, shortlisting evaluation, and scoring by a panel of independent judges. If the Customer is shortlisted, the Entry Fee also includes the provision of a Finalist’s Logo.</p>
                        <p>The Entry Fee does not guarantee shortlisting, nor does it include attendance at the Event. It is not sold on the premise that attendance at the Event is guaranteed.</p>
                        <p>If the Customer’s entry reaches the finals, the Entry Fee entitles them to present to the judges, but only as a single presenter. Additional presenters or attendance at the Awards Ceremony require payment of the Booking Fee. If the Customer does not pay the Booking Fee but still presents, they will not be permitted to watch other finalists’ presentations.</p>
                        <br/>
                        <h5>Awards Ceremony</h5>
                        <p>All winners will be announced at the Awards Ceremony.</p>
                        <p>If the winner is not in attendance, the Award will still be announced.</p>
                        <p>Agreement with these Terms of Payment is a pre-condition of attendance at the Awards Finals.</p>
                        <p>Entry to the Awards, places no obligation on the Company that the entrant will receive an award.</p>
                        <br/>
                        <h5>Non-attendance at Awards Ceremony</h5>
                        <p>If the Customer does not book Finalists’ Tickets to attend the Awards Finals and Ceremony after entering the Awards they can still win providing that have presented to the Judging Panel at the Finals.</p>
                        <p>If the Customer does not book Finalists’ Tickets they will not be entitled to items listed on the relevant website that the tickets includes.</p>
                        <p>Any Winning Customer who has not booked Finalists’ Tickets may purchase a Winners’ Package as outlined below. Prices include the Additional Winners’ Packages listed on the relevant website, where applicable.</p>
                        <br/>
                        <h5>In-Person Awards Ceremony</h5>
                        <p>
                            <b>Overall Winner</b> – Up to £8,000 <br/>
                            <b>Gold Winner</b> – Up to £6,000 <br/>
                            <b>Silver Winner</b> – Up to £5,000<br/>
                            <b>Bronze Winner</b> – Up to £4,000 <br/> 
                            <i>(All prices are subject to VAT.)</i>
                        </p>
                        <p>If a Customer wins multiple awards in the same programme, they will be charged the highest Winners’ Package price, with a 50% discount applied to each additional award won.</p>
                        <br/>
                        <h5>Online Awards Ceremony</h5>
                        <p>
                            <b>Overall Winner</b> – Up to £5,000 <br/>
                            <b>Gold Winner</b> – Up to £3,000 <br/>
                            <b>Silver Winner</b> – Up to £2,000 <br/>
                            <b>Bronze Winner</b> – Up to £1,000 <br/> 
                            <i>(All prices are subject to VAT.)</i>
                        </p>
                        <p>The same multiple-win discount applies: the Customer will be charged for the highest Winners’ Package, with a 50% discount on any additional awards won in the same programme.</p>
                        <p>The Winning Customer will then be invoiced in accordance with the prices of the Winners Packages above. Payment should be made in accordance with the Payment Terms within this policy.</p>
                        <p>The price of the Winners Packages may vary, in which case the correct information will be provided to the customer at the time.</p>
                        <br/>
                        <h5>Changes to Event Dates, Venues and Features</h5>
                        <p>The Customer agrees that dates of Events and activities may vary from those advertised on the website or other marketing information.</p>
                        <p>The Customer agrees such changes may be made at the sole discretion of the Company. If an Event cannot be held for any reason at an advertised venue or date, the Company reserves the right to cancel or postpone the Event or move it to a different venue.</p>
                        <p>The Company will notify the Customer of any changes which affect them, as soon as possible.</p>
                        <p>The Customer shall have no claim against the Company in respect of any loss or damage consequent upon the activity or activities from failing to occur wholly or partially, for whatsoever reason save where such loss or damage is caused by the company’s negligence, fraud or willful default.</p>
                        <p>This includes postponement or cancellation of the Event or parts of the Event.</p>
                        <p>The Company may substitute other activities or Events in place of planned Events and in the event of such decisions, will normally consult with Customers and to the best of its ability give prior notice to the Customer.</p>
                        <p>Neither party to these Payment Terms shall without previous consent of the other party assign any rights or obligations hereunder, unless otherwise stated in the Payment Terms. Employees and agents for the Company shall not in any circumstances be liable for consequential, indirect or special damages of any kind arising out of or in any way connected with the performance or failure to perform the awards services</p>
                        <br/>
                        <h5>Scoring and Judging (Awards)</h5>
                        <p>There will be no communication between the judges and those submitting entries regarding judging criteria, process, and decisions on the winners.</p>
                        <p>The judges’ decisions are final regarding all entries and the decisions on the winners.</p>
                        <p>The Company secures that all judges who are assessing Customers’ entries, sign an NDA which secures that no no judges will have conflict of interest with the entries or companies they are scoring and that the judges will not distribute the content of the entries publically (not limited to internal stakeholder’s meetings, public distribution or anywhere outside the Awards Manager platform). </p>
                        <br/>
                        <h5>Promotion and Marketing</h5>
                        <p>The Customer agrees that photographs and videos may be taken by the Company or its representatives of any attendees at the Events. The copyright of such materials belongs to the Company, and the Company may use these photographs and videos for marketing purposes, including on the Company’s website. The Company will ensure that the Customers are presented in a professional and distinguished manner in any promotional content.</p>
                        <p>In addition, quotes or comments shared on social media may be captured and attributed to the author, where the author is known, and may be used by the Company for marketing purposes. The Company will carefully consider any requests for removal of photographs, but the final decision will rest with the Company.</p>
                        <p>The Customer agrees not to amend, publish, make available to the public, or use any of the Company’s copyrighted images (such as logos, promotional banners, etc.) for promotional purposes without prior written permission from the Company.</p>
                        <br/>
                        <h5>Attendance at Events</h5>
                        <p>Should any member of the Customer’s party behave in a manner that is unacceptable to the Company they may be asked to leave the Event.</p>
                        <p>In this case the Customer will be liable to the Company for payment in full for all or any expenses incurred and for any fees that may ordinarily have been due for attendance or participation in such activities.</p>
                        <p>In such case the Customer may also be liable for any damages or compensation for damages caused under the circumstances.</p>
                        <p>The Company will cooperate with all Customers to the best of its ability in all matters. The Company will not be liable for any loss or damage to the property of the Customer. In the case of loss or damage to property, it should be reported to the venue holders or the Company at the time of discovery.</p>
                        <p>For business conducted with Awards International Ltd, the construction, validity and performance of these Payment Terms shall be governed by the English law and both parties agree to submit to the non-exclusive jurisdiction of the English courts.</p>
                        <p>Nothing in these Terms of Payment create legal rights, for, or enforceable by, any party other than the Customer and the Company.</p>
                    </div>
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default TermsOfPayment