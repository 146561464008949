/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'

/**
 * Components
 */

import Footer from '../components/footer'
import VerticalStepper from '../components/vertical-stepper'
import PriceAccordion from '../components/prices-accordion'

/**
 * Hooks
 */

import useScrollTrigger from '../hooks/scroll-trigger.hook'


const EnterTheAwards = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const handleToggle = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    useScrollTrigger('.reveal')
        
    return(
        <>
            <section className='bg-white py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>About the Awards</h1>
                </div>
            </section>


            <section className='bg-gradient-pastel text-ai-gray-900'>
                    <div>
                        <h3 className="self-start reveal top">Why enter</h3>
                        <p className='reveal top'>We could give you countless reasons to enter the UK Customer Experience Awards™, but we think our customers say it best. Here’s why they keep coming back:</p>

                        <p className='reveal top font-bold'>"The range of categories is vast, with clear instructions for entering. I felt informed throughout the journey, and the judges were friendly and supportive."  (Ethos Farm)</p>
                    </div>
            
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 mt-6'>
                        <div>
                            <ul className='list'>
                                <li className='reveal top'>Gain <span className='font-bold'>external validation</span> from top industry professionals.</li>
                                <li className='reveal top'>Boost your company’s <span className='font-bold'>value and reputation</span> in the market.</li>
                                <li className='reveal top'>Strengthen your team with a <span className='font-bold'>fun, rewarding experience</span> - a chance to celebrate together at a gala event.</li>
                            </ul>
                        </div>
                        <div>
                            <ul className='list'>
                                <li className='reveal top delay-300'>Enhance your appeal to internal <span className='font-bold'>stakeholders and employees</span>.</li>
                                <li className='reveal top delay-300'>Network with a <span className='font-bold'>diverse range of companies</span> and expand your connections.</li>
                                <li className='reveal top delay-300'>Showcase your <span className='font-bold'>exceptional customer service</span> and reward your team for their dedication and success.</li>
                            </ul>
                        </div>
                    </div>    
                    <div>
                        <p className='reveal top'>Entering the UKCXA™ is more than just an opportunity to win—it’s a chance to grow, celebrate, and elevate your organisation to new heights. Join us and see why businesses across the UK continue to participate year after year.</p>

                        <button className='self-start dark'>
                            <Link to={'https://awintern.com/registration?program=65&type=ENTRANT'} target='_blank'>
                                <div>Enter Now</div>
                            </Link>
                        </button>
                    </div>
            </section>
            
            
            
                <section className="bg-gray-200 text-ai-gray-900 pt-0">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-8">
                        <aside className="self-start lg:sticky top-0 lg:top-16 lg:col-span-1 reveal top">
                            <div className="w-full h-32"></div>
                            <h3>Your Journey to Success</h3>
                        </aside>
                        
                        <main className="lg:col-span-2 mt-24 reveal top">
                            <VerticalStepper props={{
                                steps: [
                                    // { mark: '1', title: 'How to Write an Award-Winning Entry', date: '9th April 2025, 12 PM UK Time', content: `<p>Mark your calendars for expert advice on transforming your incredible work into a compelling, award-winning story! Join a free webinar hosted by Donna O'Toole, Founder & MD of August Recognition.</p>` },
                                    // { mark: '3', title: 'How to Prepare an Award-Winning Presentation', date: '24th July 2025, 12 PM UK Time', content: '<p>Donna returns to share expert tips on crafting a powerful presentation to showcase your achievements.</p>' },
                                    { mark: '1', title: 'Select Categories', date: 'Deadline for submissions: 4th July 2025', content: '<p>Choose the categories that best match your business initiative(s) and complete the payment. After payment, you\'ll gain access to the <b>Awards Manager™</b>, where you can submit your entry(ies) and upload supporting material by <b>4th July 2025</b>.</p>' },
                                    { mark: '2', title: 'Finalists Announcement', date: '18th July 2025, 3 PM UK Time', content: '<p>After submitting your written entry, it will be reviewed by a shortlisting panel. If successful, your entry will be shortlisted, and you’ll take the next step toward recognition.</p>' },
                                    { mark: '3', title: 'Book Seats for the Ceremony', date: 'Early bird prices available until 5th Septemer 2025', content: `<p>Celebrate in style with your team! Enjoy discounted booking prices until <b>5th September</b>. The final chance to book is <b>2nd October 2025</b>-don’t miss out!</p>` },
                                    { mark: '4', title: 'Finalists Presentations', date: '3rd October 2025', content: `<p>Present your achievements live in an online environment to the expert judging panel. This is your opportunity to shine!</p>` },
                                    { mark: '5', title: 'Attend the Build-up and the Awards Ceremony', date: '16th October 2025', content: `<p>Join us for the Build-Up to the Awards and the grand Awards Ceremony. Celebrate excellence and network with industry leaders at this spectacular event.</p>` },
                                    { mark: '6', title: 'Receive Benchmark Report and watch Awards TV', date: '30th October 2025', content: `<p>Finalists’ <b>Benchmark reports</b> will be issued, and you’ll gain access to <b>Awards TV</b>, where you can watch recordings of the finalists' open presentations.</p>` },
                                ]
                            }} />
                        </main>
                    </div>
                    <div>
                        <div className='reveal top'>
                            <h3 className='mt-10'>Explore the Plan2Win Brochure</h3>
                            <p>Get a detailed overview of key dates, preparation webinars, category descriptions, scoring criteria, and more. Plan your journey to success!</p>
                            <button className='self-start dark'>
                                <Link to={'/plan2win'}>
                                    <div>Plan2Win</div>
                                </Link>
                            </button>
                        </div>
                    </div>
                </section>

                <section className='bg-white text-ai-gray-900'>
                    <div>
                        <h2 className="bg-gradient-analagous-rose-triad-orange self-start reveal top">How Entries are Scored</h2>
                        <p className='reveal top'>Each entry is assessed by an average of four judges per panel. The scoring process has two phases.</p>

                        <ul className='list'>
                            <li className='reveal top'>In <b>Phase 1</b>, judges evaluate written entries against seven criteria. CX Professionals categories are assessed on three criteria.</li>
                            <li className='reveal top'>In <b>Phase 2</b>, judges score live presentations based on six criteria. The written summary is not included.</li>
                            <li className='reveal top'>Finalists can further explain their presentation in a <b>Q&A session</b> with the judges. Each phase contributes <b>50%</b> to the total score.</li>
                            <li className='reveal top'>Judges score independently and don’t know their panel members before finals day. They sign <b>NDAs</b> and spend <b>6.5 hours</b> on each entry.</li>
                            <li className='reveal top'>All entries, presentations, and scoring happen on <b>Awards Manager™</b>. This system ensures <b>transparency, security, and fairness</b> for all participants.</li>
                        </ul>

                        <p className='reveal top'>For full details about the scoring process and many more, visit the Plan2Win page.</p>
                        
                        <div className='reveal top'>
                            <button className='self-start'>
                                <Link to={`/plan2win`}>
                                    <div>Plan2Win</div>
                                </Link>
                            </button>
                        </div>

                    </div>
            </section>

            <section className='bg-white text-ai-gray-900'>
                <div>
                    <h2 className="bg-gradient-analagous-rose-triad-orange self-start reveal top">Entry Fees</h2>

                    {/* <PriceAccordion 
                    heading="Super Early Bird" 
                    date="28th Mar" 
                    firstBoxHeading="Single Entry"
                    firstBoxPrice="£399" 
                    secondBoxHeading="Additional Entry"
                    secondBoxPrice="£319" 
                    thirdBoxHeading="4-entry pack"
                    thirdBoxPrice="£1,299"
                    toggle={() => handleToggle(0)}
                    open={activeIndex === 0}/> */}

                    <PriceAccordion 
                    heading="Early Bird" 
                    date="16th May" 
                    firstBoxHeading="Single Entry"
                    firstBoxPrice="£499" 
                    secondBoxHeading="Additional Entry"
                    secondBoxPrice="£399" 
                    thirdBoxHeading="4-entry pack"
                    thirdBoxPrice="£1,499" 
                    toggle={() => handleToggle(0)}
                    open={activeIndex === 0}/>

                    <PriceAccordion 
                    heading="Late Bird" 
                    date="27th June" 
                    firstBoxHeading="Single Entry"
                    firstBoxPrice="£599" 
                    secondBoxHeading="Additional Entry"
                    secondBoxPrice="£479" 
                    thirdBoxHeading="4-entry pack"
                    thirdBoxPrice="£1,799" 
                    toggle={() => handleToggle(1)}
                    open={activeIndex === 1}/>

 
                    <div className='reveal top'>
                        <button className='self-start'>
                            <Link to={`https://awintern.com/registration?program=65&type=ENTRANT`} target='_blank'>
                                <div>Enter Now</div>
                            </Link>
                        </button>
                    </div>
                    
                </div>
            </section>
            
                <section className="bg-gray-100 text-ai-gray-900">
                    <div>
                        <h2 className="bg-gradient-analagous-rose-triad-orange reveal top">Get in touch</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 pt-8">
                            <div>
                                <h4 className='reveal top font-semibold'>Neena Miskelly <br/> <span className="font-normal">Awards Consultant</span></h4>
                                <p className='reveal top'></p>
                            </div>
                            <div>
                                <p className="reveal top delay-300">
                                    Email: <Link className='mailto' to='mailto:neena@awardsinternational.com'>neena@awardsinternational.com</Link> <br/>
                                    Phone Number: +44 7383 329903
                                </p>
                            </div>
                        </div>
                        <div className='reveal top'>
                            <button className='self-start dark'>
                                <Link to='https://awardsinternational.zohobookings.com/#/neenamiskelly' target='_blank'>
                                    <div>Book a call</div>
                                </Link>
                            </button>
                        </div>
                    </div>
                </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
        
    ) 
}

export default EnterTheAwards