/**
 * Core
 */
import React from 'react'

/**
 * Components
 */
import Footer from '../components/footer'
import { TheForm } from './form'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


const Plan2JudgeForm = () => {

    useScrollTrigger('.reveal')
    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Plan2Judge</h1>
                </div>
            </section>
            <section className='bg-gradient-pastel text-ai-gray-900 pt-16'>
                <div>
                    <p className='reveal top'>The Plan2Judge brochure is your essential guide to the Awards. It will take you through the judging process, give you tips on how to apply and make the most of your judging experience, and point out various benefits of becoming an awards judge.</p>
                </div>
            </section>

            <section className='bg-gray-200 text-ai-gray-900'>
                <div className='reveal-top'>
                    <h3 className='mb-16'>Submit your details to access the Plan2Judge</h3>
                    <TheForm slug="plan2judge" />
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default Plan2JudgeForm