import { useState } from "react"

const PriceAccordion = ({ heading, date, firstBoxHeading, secondBoxHeading, thirdBoxHeading, firstBoxPrice, secondBoxPrice, thirdBoxPrice, open, toggle }) => {

  

  return (
    <>
    <div className="acc">
      <div className="acc-header shadow-md mt-10 reveal top" onClick={toggle}>
          <h4 className={`flex flex-1 items-center w-full gap-5 pb-8 pl-5 hover:cursor-pointer ${open ? "border-t-[2px] border-t-cxa_primary duration-150" : ""}`}><span className="font-symbol">{open ? 'arrow_drop_up' : 'arrow_drop_down' }</span>{heading} <i>(expires on {date})</i></h4>
      </div>

    {open && 
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-7 mt-5 pl-5">

        <div className="acc-body border-r-[1px] border-r-ai-gray-100 pb-5 duration-300">
            <p>{firstBoxHeading}</p>
            <h5 className="pt-2">{firstBoxPrice}</h5>
        </div>
        <div className="acc-body border-r-[1px] border-r-ai-gray-100 pb-5 duration-300">
            <p>{secondBoxHeading}</p>
            <h5 className="pt-2">{secondBoxPrice}</h5>
        </div>
        <div className="acc-body pb-5 duration-300">
            <p>{thirdBoxHeading}</p>
            <h5 className="pt-2">{thirdBoxPrice}</h5>
        </div>
      </div> 
      } 
    </div>
    </>
  )
}

export default PriceAccordion
