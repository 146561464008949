/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


const WinningLibrary = () => {

    useScrollTrigger('.reveal')
    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Winning Library</h1>
                </div>
            </section>
            <section className='bg-gradient-pastel text-ai-gray-900 pt-16'>
                <div>
                    <h4>Congratulations on making it this far!</h4>
                    <p className='reveal top'>We’re thrilled to provide you with final tips and resources to help you transform your achievements into a compelling story.</p>
                    <p className='reveal top'>Explore winning case studies, gain insights from the judges’ benchmark report, and make the most of the tools and support we’ve prepared for you.</p>
                    <p className='reveal top'>Enjoy the journey, and let’s create something extraordinary together!</p>
                </div>
            </section>

            <section className='bg-white text-ai-gray-900'>
                <div>

                    <h2 className='reveal top mt-20'>Case Studies</h2>
                    <p className='reveal top'>Explore the stories behind previous winners—learn how they showcased their achievements, the strategies they employed, and why they stood out to the judges. </p>
                    <p className='reveal top mb-10'>Presentation and Entry Form for all of the entries listed here</p>

                    <h3 className='text-cxa_primary mb-5 reveal top'>Landmark Information Group</h3>

                    <video width="100%" height="300px" controls src='https://cdn.awintern.com/ukcxa24_winning_library/Landmark_Information_Group.mp4'/>
                    <br/>
                    <iframe src="https://publuu.com/flip-book/45595/1780591/page/1?embed" width="100%" height="700" frameborder="0" allow="clipboard-write; autoplay; fullscreen" class="publuuflip" title='Landmark Information Group'></iframe>


 
                    <h3 className='text-cxa_primary mb-5 mt-20 reveal top'>Quirky Campers</h3>

                    <video width="100%" height="300px" controls src='https://cdn.awintern.com/ukcxa24_winning_library/Quirky_Campers.mp4'/>
                    <br/>
                    <iframe src="https://publuu.com/flip-book/45595/1780742/page/1?embed" width="100%" height="700" frameborder="0" allow="clipboard-write; autoplay; fullscreen" class="publuuflip" title='Quirky Campers'></iframe>

                    <h3 className='text-cxa_primary mb-5 mt-20 reveal top'>Dayforce</h3>

                    <video width="100%" height="300px" controls src='https://cdn.awintern.com/ukcxa24_winning_library/Dayforce.mp4'/>
                    <br/>
                    <iframe src="https://publuu.com/flip-book/45595/1782228/page/1?embed" width="100%" height="700" frameborder="0" allow="clipboard-write; autoplay; fullscreen" class="publuuflip" title='Dayforce'></iframe>    


                    <h3 className='reveal top mt-20 text-cxa_primary'>Benchmark Report</h3>
                    <p className='reveal top mb-10'>Gain valuable insights into what the judges look for and the elements that make an entry exceptional.</p>

                    <iframe src="https://publuu.com/flip-book/45595/1760348/page/1?embed" width="100%" height="700" frameborder="0" allow="clipboard-write; autoplay; fullscreen" class="publuuflip" title='Benchmark Report'></iframe>
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default WinningLibrary