import { useState } from "react"

const CatAccordion = () => {

  const categories = [
    {
      label: "CX Classics",
      category: [
        {
          name: "Customers at the Heart of Everything™",
          info: "The signature category of the UK Customer Experience Awards, traditionally the most popular, recognises companies with the most comprehensive CX approaches. This category is dedicated to organisations whose ethos is centred around customer happiness, with this commitment deeply embedded in their core values." 
        },
        {
          name: "Best Customer-Centric Culture",
          info: "This category celebrates companies that have a customer-centric mindset deeply embedded in their company culture, where every aspect of the organisation prioritises the needs and satisfaction of the customer." 
        },
        {
          name: "Best CX Strategy",
          info: "This category recognises companies with a 360-degree approach to customer experience: those who've left no stone unturned to devise a comprehensive and efficient customer experience strategy." 
        },
        {
          name: "Best Measurement in CX",
          info: "This category highlights companies that embody the mantra, 'If you can't measure it, you can't manage it.' These organisations effectively evaluate every step of the customer journey, using data to drive and enhance CX performance." 
        },
        {
          name: "Best Use of Customer Insight and Feedback",
          info: "This category recognises companies that excel in collecting, analysing, and acting upon customer insight and feedback. From gathering valuable data to implementing meaningful actions, these companies demonstrate a clear commitment to using customer feedback to drive improvements and enhance the overall experience." 
        },
        {
          name: "Best Customer Service",
          info: "This category recognises companies that excel in providing exceptional customer service, ensuring a seamless and positive experience at every touchpoint, addressing customer needs promptly, and building lasting relationships through effective support and care." 
        },
        {
          name: "Best Contact Centre",
          info: "This category is reserved for those companies that ensure the best contact centre interactions with customers. Show us how you went above and beyond to resolve customers' issues swiftly and effectively by investing in a top-notch contact centre." 
        },
        {
          name: "Best Complaint Handling",
          info: "This category celebrates companies that view complaints as a valuable opportunity for improvement. These organisations excel in handling customer complaints with care, turning feedback into actionable insights that drive service enhancement and strengthen customer loyalty." 
        }
      ]
    },
    {
      label: "CX Spotlight",
      category: [
        {
          name: "Best Innovation in CX",
          info: "Innovation drives success and customer lifetime value. This category is for forward-thinking businesses that stay ahead of the curve by successfully implementing innovative solutions and processes or introducing innovative products, features, or services, taking their customer experience to new levels." 
        },
        {
          name: "Best Business Change or Transformation",
          info: "The Business Change and Transformation category recognises organisations that have successfully implemented strategic shifts in processes, structure, culture, or technology to drive significant improvements in customer experience. This award highlights efforts that enhance efficiency, innovation, and overall customer satisfaction." 
        },
        {
          name: "Best Product or Service Development",
          info: "The Best Business Change or Service Development category celebrates companies that have excelled in adapting their products or services to meet the evolving needs of customers. This award highlights innovative approaches that prioritise customer satisfaction and deliver impactful, customer-centric solutions." 
        },
        {
          name: "Best B2B CX",
          info: "This award is for companies operating in a B2B market who provide a truly outstanding customer experience. Show us how you have created a winning strategy for achieving the best B2B results." 
        },
        {
          name: "Best BPO Partnership",
          info: "This category recognises outstanding collaborations between Business Process Outsourcing (BPO) providers and their client companies in delivering exceptional customer experiences. It celebrates partnerships that have demonstrated innovation, effectiveness, and tangible results in enhancing customer satisfaction, loyalty, and overall business performance." 
        },
        {
          name: "Best CX for Vulnerable Customers",
          info: "Vulnerability plays a significant role in ensuring the fair treatment of all customers, especially those negatively affected by life events, limited capabilities, or impairment. Implementing appropriate processes allows companies to efficiently respond and meet the needs of their vulnerable customers, making them feel protected and valued." 
        },
        {
          name: "Best Accessibility in CX",
          info: "The Best Accessibility in CX category honours organisations that have demonstrated outstanding commitment to making their customer experience accessible to all. This award recognises efforts to remove barriers and provide inclusive, seamless experiences for customers with diverse needs and abilities." 
        },
        {
          name: "Best ESG Framework",
          info: "This category is for companies that have demonstrated an ethical approach to their customer experience by successfully integrating the ESG (Environmental, Social and Governance) Framework, ensuring their customers' product and service choices have a positive impact on the society and environment." 
        }
      ]
    },
    {
      label: "DX Spotlight",
      category: [
        {
          name: "Best Digital CX",
          info: "Brands with great digital customer experience provide a cohesive and consistent online experience that delights their customers, regardless of the channel or device of choice. This category welcomes companies whose customers enjoy a seamless experience at any stage of their digital customer journey." 
        },
        {
          name: "Best Use of Technology",
          info: "Digital tools allow CX practitioners to coordinate their activities, monitor customer sentiment and execute on CX strategies like never before. If your organization has made innovative use of new and existing technologies, we'd love to hear your story in this category!" 
        },
        {
          name: "Best Digital Transformation",
          info: "Transitioning to digital is a business imperative in today's world. Implementing digital solutions can help your customers immensely, but this is rarely an easy task. This is a category for those companies that have managed to execute the best digital transformation as part of their CX efforts." 
        },
        {
          name: "Best Use of AI",
          info: "This category acknowledges organisations that harness artificial intelligence to revolutionise customer experiences. From chatbots to personalised recommendations, we celebrate innovative AI-driven solutions that enhance engagement, streamline processes, and set new benchmarks in customer-centric excellence." 
        },
        {
          name: "Best User Experience",
          info: "User experience is an essential aspect of customer experience, because it reflects the very interaction between the customer and the company. Customers want a simple and intuitive approach with as little friction as possible, and this category recognises those companies that provide just that." 
        }
      ]
    },
    {
      label: "EX Spotlight",
      category: [
        {
          name: "Best Employee Experience",
          info: "A business cannot progress and prosper in the long run without its key resource - people! The Best Employee Experience awards will go to a company that invests in their employees' wellbeing and development and acknowledges their efforts and contribution." 
        },
        {
          name: "Best Learning and Development",
          info: "In a fast-moving world, a company's success depends on enhancing the skillset of its employees. This category celebrates organisations who deliver exceptional learning and development programmes, and upskill their employees to ensure they constantly learn and grow in their roles." 
        },
        {
          name: "Best Employee-driven CX",
          info: "This category recognises outstanding efforts in providing the best customer experience with exceptional involvement and engagement from employees. How are your employees contributing to the company's CX? Share your initiative and showcase your results!" 
        }
      ]
    },
    {
      label: "CX Professionals",
      category: [
        {
          name: "CX Team of the Year",
          info: "With a skilled and dedicated team onboard, a company is ready to face the hardest of challenges and surpass them. Show us how your team contributed to your company's success and why they deserves to be recognised as the CX Team of the Year." 
        },
        {
          name: "Leader of the Year",
          info: "Behind every great team there's an amazing CX leader, inspiring everyone to get better and reach new heights. This award will go to an individual that has demonstrated remarkable leadership and improved their company's customer experience as a result." 
        }
      ]
    }
  ]

  const [isActive, setActive] = useState(0);
  
  function toggleAcc(id) {
    setActive(isActive === id ? null : id);
    console.log(isActive, id)
  }

  return (
    <>
  {categories.map((cat, index) => (

    <div className="acc" key={index}>
      <div className="acc-header shadow-md mt-10 reveal top" onClick={() => toggleAcc(index)}>
          <h4 className={`flex flex-1 items-center w-full gap-5 pb-8 pl-5 hover:cursor-pointer ${isActive === index ? "border-t-[2px] border-t-cxa_primary duration-150" : ""}`}><span className="font-symbol">{isActive === index ? 'arrow_drop_up' : 'arrow_drop_down' }</span>{cat.label}</h4>
      </div>

      {isActive === index &&

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mt-5 pl-5">
      {cat.category.map((inside, index) => (
        <div className="acc-body border-b-[1px] border-b-ai-gray-100 pb-5 duration-300" key={index}>
            <h5>{inside.name}</h5>
            <p className="pt-4">{inside.info}</p>
        </div>
      ))}
      </div>   
    }
    </div>
  ))}
    </>
  )
}

export default CatAccordion
