/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */

import Footer from '../components/footer'
import VerticalStepper from '../components/vertical-stepper'
import Slider from '../components/slider'
import Testimonial from '../components/pages/home/testimonial'

/**
 * Hooks
 */

import useScrollTrigger from '../hooks/scroll-trigger.hook'




const JudgingProcess = () => {

    useScrollTrigger('.reveal')
    
    return(
        <>
            <section className='bg-white py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Judging process</h1>
                </div>
            </section>


            <section className='bg-gradient-pastel text-ai-gray-900'>
                    <div>
                        <h3 className="self-start reveal top">How the judges are selected</h3>
                        <p className='reveal top'>We take great pride in our rigorous judging process. Judges are selected based on their expertise and standing in the customer experience industry, undergoing a thorough application process reviewed by our internal acceptance panel. Upon approval, they sign a non-disclosure agreement (NDA) to ensure confidentiality and are assigned to categories with no conflicts of interest. What makes our process truly rigorous is our commitment to transparency and fairness—we review every score from every judge, checking for consistency, accuracy, and potential bias to ensure an impartial evaluation. Additionally, judges are certified based on the quality of their assessments, professionalism, and conduct, reinforcing the integrity of the awards.</p>
                    </div>
                    
                    <div className='reveal top'>
                            <button className='self-start dark'>
                                <Link to={'/register-interest?interestType=Judge'}>
                                    <div>Apply to Judge</div>
                                </Link>
                            </button>
                        </div>
            </section>

            <section className="bg-none bg-gray-100 text-ai-gray-900">
                    <div>
                        <h2 className="bg-gradient-analagous-rose-triad-orange reveal top">Why Become a Judge at the UKCXA™?</h2>
                        <p>Judging at the UK Customer Experience Awards™ is a chance to celebrate CX excellence and share your expertise. Here are some of the benefits you can get as a judge:</p>

                        <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 mt-6'>
                            <div>
                                <ul className='list'>
                                    <li className='reveal top'>Contributing to a community that celebrates positive recognition and success.</li>
                                    <li className='reveal top'>Learning from real life initiatives through entries and presentations.</li>
                                    <li className='reveal top'>Staying updated with the latest trends and innovations in the industry.</li>
                                    <li className='reveal top'>Enhancing your professional profile and showcasing your expertise.</li>
                                </ul>
                            </div>
                            <div>
                                <ul className='list'>
                                    <li className='reveal top'>Expanding your network, connecting with peers, and building lasting business relationships.</li>
                                    <li className='reveal top'>Earning certification as a judge, recognising your professionalism and conduct.</li>
                                    <li className='reveal top'>Joining The Judge Club, an exclusive network of CX professionals.</li>
                                    <li className='reveal top'>Gain up to 12 CRC points toward renewing your CCXP accreditation.</li>
                                </ul>
                            </div>
                        </div>    
                            <div>

                                <p className='reveal top delay-300'>Join us as a judge and make a lasting impact on the world of customer experience!</p>

                                <p className='reveal top delay-300'>To discover all the benefits, access the Plan2Judge.</p>
                        
                                    <div className='reveal top'>
                                            <button className='dark self-start'>
                                                    <Link to={`/plan2judge-form`}>
                                                        <div>Access Plan2Judge</div>
                                                    </Link>
                                            </button>
                                        </div>
                            </div>
                    </div>
                </section>

                <section className='bg-white'>
                <div className='max-w-[100vw]'>
                    <Slider props={{
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        autoplay: true,
                        loop: true,
                        className: 'max-w-[100%]',
                        slideWrapperClassName: 'max-w-[100%] xl:max-w-[1128px] 2xl:max-w-[1290px] px-0 2xl:px-0',
                        slideClassName: 'rounded-3xl'
                    }}>
                        <img src='./images/slider/cxa/judge-meetup.png' alt='' />
                        <img src='./images/slider/cxa/judge-meetup-1.png' alt='' />
                        <img src='./images/slider/cxa/judge-meetup-2.png' alt='' />
                        <img src='./images/slider/cxa/judge-meetup-3.png' alt='' />
                        <img src='./images/slider/cxa/judge-meetup-greg.png' alt='' />
                    </Slider>
                </div>
            </section>
            
            
            
                <section className="bg-gradient-pastel text-ai-gray-900 pt-0">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-8">
                        <aside className="self-start lg:sticky top-0 lg:top-16 lg:col-span-1 reveal top">
                            <div className="w-full h-32"></div>
                            <h3>Judge Journey</h3>
                            <p>With the UK Customer Experience Awards being more popular than ever, judging opportunities fill up fast. Apply early to secure your spot and enjoy the benefit of selecting your prefer.</p>
                        </aside>
                        
                        <main className="lg:col-span-2 mt-24 reveal top">
                            <VerticalStepper props={{
                                steps: [
                                    { mark: '1', title: 'Finalists Announcement', date: '18th July 2025, 3 PM UK Time', content: `<p>Discover the companies that have made it to the finals! This is your chance to choose your top three preferred categories for scoring based on your expertise and interests. You’ll be assigned to one of these categories.</p>` },
                                    { mark: '2', title: 'Judge Briefing Webinar - Scoring Of Entries', date: '19th Aug 2025, 12 PM UK Time', content: '<p>Learn how to evaluate entries and provide meaningful feedback during this informative session. Mark your calendar to ensure you’re fully prepared for your judging responsibilities.' },
                                    { mark: '3', title: 'How to get the most out of being a judge - Final Judge Briefing Webinar', date: '23rd Sep 2025, 12 PM UK Time', content: '<p>Get tips on maximising your experience as a judge. This session is designed to help you make the most of your role and your time at the event.</p>' },
                                    { mark: '4', title: 'Finalists Presentations', date: '3rd October 2025', content: `<p>The excitement reaches its peak! Meet your fellow judges and witness finalists bring their stories to life through engaging presentations.</p>` },
                                    { mark: '5', title: 'Build-Up and Awards Ceremony', date: '16th October 2025', content: `<p>Attend the Build-Up to the Awards and the grand Awards Ceremony. Use this opportunity to connect with judges and finalists — pre-book catch-ups to make the most of this networking experience.</p>` },
                                    { mark: '6', title: 'Judges Feedback Report & Awards TV', date: '30th October 2025', content: `<p>Receive your Judges’ Feedback Report and a certificate (if you scored well). Gain access to Awards TV to watch recordings of finalists’ presentations online.</p>` },
                                ]
                            }} />
                        </main>
                    </div>
                    <div>
                        <div className='reveal top'>
                            <button className='self-start dark'>
                                <Link to={'/register-interest?interestType=Judge'}>
                                    <div>Apply to Judge</div>
                                </Link>
                            </button>
                        </div>
                    </div>
                </section>

                <section className='bg-white text-ai-gray-900'>
                    <div>
                        <h2 className="bg-gradient-analagous-rose-triad-orange self-start reveal top">Judging Panels</h2>
                        <p className='reveal top'>Every judge is scoring up to 8 entries across a maximum of two categories. We tend to have three to five judges per each panel (category). All judges score independently, which means that during the scoring process, they don't know who are their fellow judges till the day of the awards finals (finalists presentations or the second round of scoring). As at the UKCXA we normally have around 30 categories that means we will have 120 judges scoring finalists entries!</p>
                        <p className='reveal top'>Take a look at our Meet the Judges. The recruitment process has started, so make sure you secure your spot on time.</p>
                    </div>

                    <div className='reveal top'>
                        <button className='self-start'>
                            <Link to={'/meet-the-judges'}>
                                <div>Meet the Judges</div>
                            </Link>
                        </button>
                    </div>
            </section>

            <section className='bg-gray-200 text-ai-gray-900'>
                    <div>
                    {
                            <Slider props={{
                                slidesPerView: "auto",
                                autoplay: true,
                                loop: true,
                                className: 'max-w-[100%]',
                                slideWrapperClassName: 'max-w-[100%] xl:max-w-[1128px] 2xl:max-w-[1390px] px-0 2xl:px-0',
                                slideClassName: 'rounded-3xl'
                            }}>
                                
                                <Testimonial props={{ title: 'Luke O\'Neil - ABa Quality Monitoring Ltd', description: 'A massive thank you to Awards International for putting on such a fantastic event. I particularly enjoyed the judges\' gathering - the speed (dating) networking was a stroke of genius! I made so many valuable new connections and had a great time. 🤝 It was truly energising to be in a room filled with so many passionate CX enthusiasts. I’m looking forward to staying in touch and continuing to learn from this amazing community! 🙏 ', image: 'https://media.ainterna.com/uploads/Luke_O_Neil_f7ebaef5e4.jpg'}} />

                                <Testimonial props={{ title: 'Robyn Walters - RWR', description: "The UK Customer Experience Awards™ by Awards International is a brilliant platform that recognises the innovative efforts of the UK's customer experience professionals.", image: 'https://media.ainterna.com/uploads/996d6d16_964a_4e49_aa0f_89daa6dc63a1_Robyn_79bbb72dbc.jpg'}} />

                                <Testimonial props={{ title: 'Sumit Kumar - Creative Ideaz', description: 'I\'m thrilled to join the esteemed judging panel at UKCXA and to work alongside such talented individuals in recognising the best initiatives this year.', image: 'https://media.ainterna.com/uploads/Sumit_Kumar_d9d6c77861.jpg'}} />
                                                            
                            </Slider>
                    }
                    </div>
                </section>

            
                <section className="bg-none bg-gray-100 text-ai-gray-900">
                    <div>
                        <h2 className="bg-gradient-analagous-rose-triad-orange reveal top">Get in touch</h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 pt-8">
                            <div>
                                <h4 className='reveal top font-semibold'>Neena Miskelly <br/> <span className="font-normal">Awards Consultant</span></h4>
                                <p className='reveal top'></p>
                            </div>
                            <div>
                                <p className="reveal top delay-300">
                                    Email: <Link className='mailto' to='mailto:neena@awardsinternational.com'>neena@awardsinternational.com</Link> <br/>
                                    Phone Number: +44 7383 329903
                                </p>
                            </div>
                        </div>
                        <div className='reveal top'>
                            <button className='self-start dark'>
                                <Link to='https://awardsinternational.zohobookings.com/#/neenamiskelly' target='_blank'>
                                    <div>Book a call</div>
                                </Link>
                            </button>
                        </div>
                    </div>
                </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
        
    ) 
}

export default JudgingProcess