/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'
import Webinars from '../components/webinars'
import { TheForm } from './form'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'

import entryWebinar from "../assets/images/pages/webinars/UKCXA25_webinar_O'Toole_entry.png"
import presWebinar from "../assets/images/pages/webinars/UKCXA25_webinar_O'Toole_presentation.png"


const HowToEnterKit = () => {

    useScrollTrigger('.reveal')
    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Plan2Win</h1>
                </div>
            </section>
            <section className='bg-gradient-pastel text-ai-gray-900 pt-16'>
                <div>
                    <p className='reveal top'>Your team’s incredible work deserves the spotlight, and we’re here to support you every step of the way! Whether you need help choosing the right categories, understanding the criteria, or learning from the success of previous winners, you’ve found the right place.</p>
                    <p className='reveal top'>Here’s how we’ll help:</p>
                    <div className='grid grid-cols-1 gap-x-8'>
                        <div>
                            <ul className='list'>
                                <li className='reveal top'><b>Expert Guidance:</b> Join free webinars hosted by our partners to craft a standout entry and prepare your presentation. Mark your calendars for <b>9th April</b> and <b>20th August</b> with Donna O'Toole.</li>
                                <li className='reveal top'><b>One-on-One Support:</b> Get personalised advice with free consultations from our awards expert, Neena Miskely</li>
                                <li className='reveal top'><b>Plan2Win® Guide:</b> Your go-to resource for navigating the UK Customer Experience Awards journey with ease.</li>
                                <li className='reveal top'><b>Samples of Winning Case Studies:</b> Access valuable resources including entry forms, finalists' presentations, and benchmark reports to inspire and guide your submission.</li>
                            </ul>

                            <p className='reveal top delay-300'>Dive into the Plan2Win, a premium collection of case studies and judges’ feedback from past UKCXA winners. Discover how these stories were crafted to inspire and captivate, and use this exclusive content to elevate your own entry. Unlock your path to success today!</p>

                            {/* <div className='reveal top'>
                                <button className='dark self-start'>
                                    <Link to={`/get-started`}>
                                        <div>Access the Plan2Win</div>
                                    </Link>
                                </button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-gray-200'>

                <div className='reveal top'>
                    
                    <h3 className='text-ai-gray-900 mb-16'>Submit your details to access the Plan2Win</h3>

                    <TheForm slug="plan2win"  />
                </div>

            </section>

            <section className='bg-white text-ai-gray-900'>
                <div>
                    <p>Register for free webinars to help you put your initiative into a compelling story.</p>          
                </div>

                <div className="grid grid-col-1 md:grid-cols-2 reveal top mt-10 gap-5">

                    <Webinars img={entryWebinar} heading='How to write an outstanding entry' desc="This webinar will give you all the building blocks to start crafting your winning submission. The webinar is held by Donna O'Toole who has been named one of the world's top 25 Customer Experience Influencers and is an award-winning national and international awards judge. Don't miss it, see you there!" link='https://us02web.zoom.us/webinar/register/WN_vmgS1HjiREu-SZNpISU-fw'/>

                    <Webinars img={presWebinar} heading='How to create a winning presentation' desc='During this webinar Donna will help you create a presentation that wows the judges. Register now for this hugely insightful webinar and learn the secrets to winning awards.' link='https://us02web.zoom.us/webinar/register/WN_UKFJh6VzQE2ECmQAF6cF3g'/>

                 </div>      
            </section> 
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default HowToEnterKit