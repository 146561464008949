import { getMeta } from "../config/routes"


export const meta = (path) => {

    const meta = getMeta(path)

    let result

    for(const [metaProperty, metaValue] of Object.entries(meta.props)) {
        if(metaProperty === 'title') {
            const title = (meta !== null && meta['title']) || metaValue
            result = `${result}\n<title>${title}</title>`
            result = `${result}\n<meta property="og:title" content="${title}" />`
        } else if (metaProperty === 'description') {
            const description = (meta !== null && meta['description']) || metaValue
            result = `${result}\n<meta property="og:description" content="${description}" />`
            result = `${result}\n<meta name="description" content="${description}" />`
        } else {
            const content = (meta !== null && meta[metaProperty]) || metaValue
            result = `${result}\n<meta property="${metaProperty}" content="${content}" />`
        }        
    }

    return result

}