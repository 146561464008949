import { useState } from "react"
import { Link } from "react-router-dom";


const SponsorsCard = ({ img, heading, desc, bio, website_url}) => {

    const [isActive, setActive] = useState(false);

    function learnMore() {
        setActive(!isActive);
    }
  return (
        <div className="mt-10 border-[1px] border-ai-gray-100 shadow-md rounded-xl p-5 flex flex-1 items-center gap-8 max-md:flex-col">
            
            <div className="w-1/3 max-md:w-full self-start">
            <Link to={website_url} target="_blank">
                <div className="border-[1px] border-ai-gray-100 rounded-xl flex justify-center p-2 shadow-xl hover:shadow-2xl">
                    <img src={img} alt="logo-img" width={250}/>
                </div>
            </Link>
            </div>
            

            <div className="w-[60%] max-md:w-full">

                <p className="font-bold text-ai-grey-900 pt-0">{heading}</p>
                <p className="text-ai-grey-900 mb-5">{desc}</p>
                
                <div>

                <div onClick={() => learnMore()} className="text-base text-ai-gray-900 mb-5 flex items-center hover:cursor-pointer hover:text-cxa_primary hover:duration-300">Learn More <span className="font-symbol">{isActive ? 'arrow_drop_up' : 'arrow_drop_down' }</span></div>

                
                <div  className={`overflow-hidden transition-all duration-500 ${isActive ? "max-h-96" : "max-h-0"} text-ai-gray-900`}>
                    <p className="text-[18px] pt-0">{bio}</p>
                    <p className="text-[18px] pt-2"><Link to={website_url} target="_blank" className="text-cxa_primary">Website {'>'}</Link></p>
                 </div>
                
                </div>
            </div>
        </div>
  )
}

export default SponsorsCard
