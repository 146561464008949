/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'
import CatAccordion from '../components/cat-accordion'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'

/**
 * meta
 */
import { meta } from '../utils/meta'
import rehypeRaw from 'rehype-raw'
import ReactMarkdown from 'react-markdown'


const Categories = () => {

    useScrollTrigger('.reveal')
    const metaData = meta('categories')
    
    return (
        <article>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{metaData}</ReactMarkdown>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Categories</h1>
                </div>
            </section>
            <section className='bg-gradient-pastel text-ai-gray-900 pt-16'>
                <div>
                    <p className='reveal top'>This year, the UK Customer Experience Awards™ features 26 categories, thoughtfully organised into five distinct groups: <b>CX Classics</b>, <b>CX Spotlight</b>, <b>DX Spotlight</b>, <b>EX Spotlight</b>, and <b>CX Professionals</b>. Each group shares similar criteria, allowing you to easily select multiple categories and increase your chances of winning.</p>

                    <p className='reveal top'>Whether you're aiming to highlight your innovative CX initiatives, showcase exceptional employee experience strategies, or recognise individual achievements in the industry, there's a category for every standout performance.</p>

                    <div className='grid grid-cols-1 gap-x-8'>
                        <div>
                            <ul className='list'>
                                <li className='reveal top'><b>CX Classics:</b> Recognising established leaders in customer experience.</li>
                                <li className='reveal top'><b>CX Spotlight:</b> Spotlighting cutting-edge CX innovations and initiatives.</li>
                                <li className='reveal top'><b>DX Spotlight:</b> Highlighting innovation and excellence in digital transformation across industries and customer experiences.</li>
                                <li className='reveal top'><b>EX Spotlight:</b> Honouring the exceptional work that drives employee experience excellence.</li>
                                <li className='reveal top'><b>CX Professionals:</b> Celebrating the individuals who lead and inspire CX success.</li>
                            </ul>

                            <p className='reveal top delay-300'>Each year, the UKCXA™ continues to evolve, bringing attention to the companies and professionals pushing the boundaries of customer and employee experience.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-white text-ai-gray-900'>

                <div>

                    <CatAccordion />

                    <div className='reveal top mt-10'>
                        <button className='self-start'>
                            <Link to={`https://awintern.com/registration?program=65&type=ENTRANT`} target='_blank'>
                                <div>Enter Now</div>
                            </Link>
                        </button>
                    </div>

                </div>
            </section>

            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </article>
    )
}

export default Categories