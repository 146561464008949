/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'
import ImageScroller from '../components/image-scroller'
import SponsorsCard from '../components/sponsors-card'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


const SponsorsAndPartners = () => {

    useScrollTrigger('.reveal')

    const scrollingLogos = ['cemantica.jpg', 'clientship.png', 'ipsos.png', 'PIC.png', 'SELT.png', 'Sudale.jpg', 'Davies.png', 'Skewb.png', 'Kantar.svg']
    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Sponsors and Partners</h1>
                </div>
            </section>
            <section className='bg-gray-100 text-ai-gray-900 pt-16'>
                <div>
                    <p className='reveal top'>The UK Customer Experience Awards bring together the people driving change and innovation in customer experience. This event is a chance to connect with decision-makers from some of the UK’s biggest and most influential businesses.</p>
                    <p className='reveal top'>Sponsoring the UKCXA gives you the opportunity to:</p>
                </div>

                <div className='grid grid-cols-1 gap-x-8 mt-6'>
                    <div>
                        <ul className='list'>
                            <li className='reveal top'><b>Reach Decision-Makers:</b> Meet the key players shaping the future of CX.</li>
                            <li className='reveal top'><b>Showcase Your Business:</b> Share your expertise with an audience looking for innovative solutions.</li>
                            <li className='reveal top'><b>Grow Your Network:</b> Build partnerships and connect with businesses that value what you offer.</li>
                        </ul>
                    </div>
                </div>

                <div>
                    <p className='reveal top'>The earlier you start supporting the UK Customer Experience Awards, the greater and longer-lasting exposure you’ll enjoy with participants. Sponsoring the awards also pairs perfectly with the added benefits offered by Customer Experience Magazine, giving you even more opportunities to amplify your brand and connect with key decision-makers in the CX industry.</p>
                    <p className='reveal top'>Don’t miss the opportunity to amplify your brand and connect with the leading voices in CX!</p>
                </div>
            </section>

            <section className='bg-white'>
                <div>
                    <h2 className='reveal top'>Our Partners</h2>
                    
                    <div className="grid grid-cols-1 reveal top">
                        <SponsorsCard img='https://media.ainterna.com/uploads/CXPA_3daf203a21.png' heading='Customer Experience Professionals Associations (CXPA)' desc='UK Customer Experience Awards and CXPA are proud to be global partners in advancing CX.' bio='Since 2011, CXPA has been committed to advancing the customer experience profession. CXPA supports the professional development of its members and advances the field by providing shared best practices and education, developing standards, offering networking opportunities, promoting the industry, and creating a better understanding of the discipline of customer experience.' website_url='https://www.cxpa.org/home'/>

                        <SponsorsCard img='https://media.ainterna.com/uploads/Barnardos_logo_wide_c7361d6ea5.png' heading='Barnardo’s' desc='Partnering with Barnardo’s, UK Customer Experience Awards are committed to supporting vulnerable children.' bio='Barnardo’s believes in children regardless of their circumstances, gender, race, disability, or behaviour. Their purpose as a charity today is to transform the lives of the UK’s most vulnerable children. During the UK Customer Experience Awards ceremonies, we make a special effort to raise funds for Barnardo’s through raffles. It’s heart-warming to see so many people come together, not only to celebrate excellence in customer experience but also to make a real, tangible difference in the lives of those who need it most. Since we started raising funds, we are proud to have contributed an incredible £171k to Barnardo’s, helping support their vital work and making a lasting impact on the lives of the children.' website_url='https://www.barnardos.org.uk/'/>
                    </div>

                </div>
            </section>

            <section className='bg-white'>
                <div>
                    <h2 className='reveal top'>Previous Sponsors</h2>
                </div>  

                <ImageScroller props={{ folder: './images/scrolling-logos/', images: scrollingLogos }}/>

                <div>
                    <div className='flex flex-1 gap-5 items-center mt-10'>
                            <button>
                                <Link to={`/register-interest?interestType=Sponsor`}>
                                    <div>Explore the Sponsors Kit</div>
                                </Link>
                            </button>
                            {/* <button>
                                <Link to={`/register-interest?interestType=Partner`}>
                                    <div>Enquire about Partnership</div>
                                </Link>
                            </button> */}
                    </div>
                </div>
            </section>

            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default SponsorsAndPartners