/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'

/**
 * Components
 */
import Footer from '../components/footer'
import Bio from '../components/pages/about/bio'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


const MeetTheJudges = () => {

    useScrollTrigger('.reveal')

        const [judges, setJudges] = useState([]);

        useEffect(() => {
            fetch('https://api.awintern.com/api/v1/judge_certifications/programs/52')
            .then((res) => {
                return res.json(); 
            })
            .then((data) => {
               console.log(data);
               setJudges(data);
            })
            .catch((error) => {
                console.error('Error:', error); 
            });
        }, [])    

    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Meet the Judges</h1>
                </div>
            </section>
            <section className='bg-gray-100 text-ai-gray-900 pt-16'>
                <div>
                    <p className='reveal top'>Every entry at UK CXA™ is assessed by an independent judging panel.</p>
                    <p className='reveal top'>All our judges are seasoned professionals with distinguished CX careers; many have judged with us several times before and keep coming back!</p>
                    <p className='reveal top'>Using the scoring criteria, they’ll carefully scrutinise your written submission and live presentation. Scores from each judge are added together to determine the winners in each category. After the event, every finalist will receive a detailed Feedback Report with constructive comments that help you improve your CX going forward.</p>
                    <p className='reveal top'>Our judge selection process is ongoing, but as soon as we add more judges to our line-up, we’ll post their details on here. Scroll down to see who's taking part this year!</p>
                    <p className='reveal top'>If you’re interested in becoming a judge, please click the link below, or email Neena at <Link className='mailto' to='mailto:neena@awardsinternational.com'>neena@awardsinternational.com</Link></p>

                    <div className='reveal top'>
                        <button>
                            <Link to={'/register-interest?interestType=Judge'}>
                                <div>Apply to Judge</div>
                            </Link>
                        </button>  
                    </div>
                </div>
            </section>

            <section className='bg-gray-100 text-ai-gray-900'>

            <div>
                <h3 className='reveal top text-cxa_primary'>Our Previous Judges</h3>
            </div>

            <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-32 mt-32 reveal top'>
                
                {judges.map((judge, index) => (

                    <Bio key={index} props={{ title: `${judge.name}`, subtitle: `${judge.job_title}`, company: `${judge.organization}`, 
                    image: `https://awintern.com${judge.profile_image ? judge.profile_image : "/assets/img/user-solid.svg"}`, className: '!bg-white' }} />
                ))}

            </div>

            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default MeetTheJudges