/**
 * Core
 */
import { useEffect, useState } from 'react'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'

/**
 * Styles
 */
import './App.scss'

/**
 * Components
 */
import Home from './pages/home'
import AboutUs from './pages/about-us'
import GetStarted from './pages/get-started'
import TheJudgeClub from './pages/the-judge-club'
import TopStories from './pages/top-stories'
import TopStory from './pages/top-story'
import Events from './pages/events'
import Awards from './pages/awards'
import PrivacyPolicy from './pages/privacy-policy'
import TermsOfUse from './pages/terms-of-use'
import Media from './pages/media'
import CookiesConsent from './components/cookies-consent/cookies-consent'
import NoMatch from './pages/no-match'
// import Splash from './components/splash'
import ThankYou from './pages/thank-you'
import CertifiedJudges from './pages/certified-judges'
import EnterTheAwards from './pages/enter-the-awards'
import Categories from './pages/categories'
import HowToEnterKit from './pages/how-to-enter-kit'
import JudgingProcess from './pages/judging-process'
import EventDetails from './pages/event-details'
import KitResources from './pages/kit-resources'
import Plan2Judge from './pages/plan2judge'
import NewsAndInsight from './pages/news-and-insight'
import SponsorsAndPartners from './pages/sponsors-and-partners'
import WinningLibrary from './pages/winning-library'
import MeetTheJudges from './pages/meet-the-judges'
import Plan2JudgeForm from './pages/plan2judge-form'
import SteeringCouncil from './pages/steering-coucnil'
import TermsAndConditions from './pages/terms-and-conditions'
import TermsOfPayment from './pages/terms-of-payment'

import Menu from './components/menu'
import Form from './components/form'
import FormPage from './pages/form'
import MenuTest from './components/menu-test'

/**
 * Context
 */
import { NavigationContext } from './context/navigation.context'
import { SearchProvider } from './context/search.context'

/**
 * Utils
 */
import { subscribe, unsubscribe } from './utils/event'
import forms from './config/forms'
import routes from './config/routes'
import useMeta from './hooks/meta.hook'

/**
 * Cookies
 */
import { CookiesProvider, useCookies } from 'react-cookie'




const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'instant'})
  }, [pathname])

  return null
}

const App = () => {
  const location = useLocation()
  const [formData, setFormData] = useState(null)
  const [openFormIn, setOpenFormIn] = useState(null)
  const navigate = useNavigate()
  
  const [cookies, setCookie] = useCookies()
  const [cookiesOn, setCookiesOn] = useState(false)

  const [splash, setSplash] = useState(true)
  const [meta, setMeta] = useState(null)
  
  useEffect(() => {
    subscribe('formTriggered', (e) => handleFormTriggered(e))
    subscribe('formClosed', () => { setFormData(null) } )

    setTimeout(() => { setSplash(false) }, 3000)

    return () => {
        unsubscribe('resourceDownloadTriggered')
        unsubscribe('formClosed')
    }
  }, [])

  useEffect(() => {
    if(!cookies.hasOwnProperty('_awardsinternationalcom_cookiesConsent')) setCookiesOn(true)
    const m = routes.find(route => route.path === location.pathname)
    setMeta(m)
  }, [location])
  const handleFormTriggered = (e) => {
    setFormData(forms.find(form => form.slug == e.detail.slug))
    if(e.detail.openIn == 'page') navigate(`/${e.detail.slug}`)
    //setOpenFormIn(e.detail.openIn)
  }

  
  return (
    <>
      {/* { splash && <Splash /> } */}
      <CookiesProvider>
        <NavigationContext.Provider value={{ location, headerSquezeed: location.pathname === '/' ? false : true }}>
          <SearchProvider>
            <div className={ location.pathname === '/' ? 'mt-36' : 'relative mt-24' }>
                <ScrollToTop />
                <Menu />
                {/* { cookiesOn && <CookiesConsent handleCookiesConsent={() => setCookiesOn(!cookiesOn)} callback={(data) => { setCookie('_awardsinternationalcom_cookiesConsent', encodeURIComponent(JSON.stringify(data))) }}/> } */}
                <Routes>
                  <Route path='/' element={<Home />}/>
                  <Route path='/about-us' element={<AboutUs />}/>
                  <Route path='/get-started' element={<GetStarted />}/>
                  <Route path='/the-judge-club' element={<TheJudgeClub />}/>
                  <Route path='/top-stories' element={<TopStories />}/>
                  <Route path='/top-story/:slug' element={<TopStory />}/>
                  <Route path='/events' element={<Events />}/>
                  <Route path='/awards' element={<Awards />}/>
                  <Route path='/how-to-enter' element={<EnterTheAwards />}/>
                  <Route path='/categories' element={<Categories />}/>
                  <Route path='/plan2win' element={<HowToEnterKit />}/>
                  <Route path='/apply-to-judge' element={<JudgingProcess />}/>
                  <Route path='/meet-the-judges' element={<MeetTheJudges />}/>
                  <Route path='/book-now' element={<EventDetails />}/>
                  <Route path='/plan2win-download' element={<KitResources />}/>
                  <Route path='/plan2judge' element={<Plan2Judge />}/>
                  <Route path='/plan2judge-form' element={<Plan2JudgeForm />}/>
                  <Route path='/sponsors-and-partners' element={<SponsorsAndPartners />}/>
                  <Route path='/news-and-insight' element={<NewsAndInsight />}/>
                  <Route path='/steering-council' element={<SteeringCouncil />}/>
                  <Route path='/winning-library' element={<WinningLibrary />}/>
                  <Route path='/register-interest' element={<FormPage slug="register-interest" />}/>
                  <Route path='/privacy-policy' element={<PrivacyPolicy />}/>
                  <Route path='/terms-and-conditions' element={<TermsAndConditions />}/>
                  <Route path='/terms-of-payment' element={<TermsOfPayment />}/>
                  <Route path='/terms-of-use' element={<TermsOfUse />}/>
                  <Route path='/media' element={<Media />}/>
                  <Route path='/thank-you' element={<ThankYou />}/>
                  <Route path='/certified-judges' element={<CertifiedJudges />}/>
                  <Route path="*" element={<NoMatch />}/>
                </Routes>
                {
                  formData !== null && openFormIn == 'modal' && <Form formData={formData} />
                }
            </div>
          </SearchProvider>
        </NavigationContext.Provider>
      </CookiesProvider>
    </>
  )
}

export default App
