const forms = [
    {
        "slug": "register-interest",
        "name": "Register Interest",
        "formPerma": "https://forms.zohopublic.com/awardsinternational/form/UKCXA25RegisterInterest/formperma/NiF-4QUWGrHLAYME15ayYwDBKfcIPCSxhIkt4TcnlY8/records",
        "thankYouMessage": `
            <h4>Thank you for registering your interest in the UK Customer Experience Awards 2025. </h4>
            <p>We look forward to providing you with valuable updates.</p>
            <p>
                Kind regards,<br />
                <strong>UK Customer Experience Awards Team</strong>
            </p>`,
        "definition": {
            "fields": [
                {
                    "display_name": "Name",
                    "key": "Name",
                    "type": "input",
                    "variant": "group",
                    "mandatory": true,
                    "fields": [
                        {
                            "display_name": "First Name",
                            "key": "Name_First",
                            "id": "firstName",
                            "type": "input",
                            "variant": "text",
                            "validation": [
                                { "type": "notEmpty", "message": " is mandatory."}
                            ],
                            "mandatory": true,
                        },
                        {
                            "display_name": "Last Name",
                            "key": "Name_Last",
                            "id": "lastName",
                            "type": "input",
                            "variant": "text",
                            "mandatory": true,
                            "validation": [
                                { "type": "notEmpty", "message": " is mandatory."},
                            ],
                        }
                    ]
                },
                {
                    "display_name": "Company",
                    "key": "SingleLine",
                    "id": "company",
                    "type": "input",
                    "variant": "text",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Job Title",
                    "id": "title",
                    "key": "SingleLine1",
                    "type": "input",
                    "variant": "text",
                    "mandatory": false,
                },
                {
                    "display_name": "Phone",
                    "key": "SingleLine2",
                    "id": "phone",
                    "type": "input",
                    "variant": "number",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Email",
                    "key": "Email",
                    "id": "email",
                    "type": "input",
                    "variant": "email",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Choose Your Interest",
                    "key": "Dropdown",
                    "id": "interestType",
                    "type": "select",
                    "variant": "select",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                    "choices": [
                        { "value": "Enter", "key": "Enter" },
                        { "value": "Judge", "key": "Judge" },
                        { "value": "Sponsor", "key": "Sponsor" },
                        { "value": "Partner", "key": "Partner" }
                    ],
                },
                {
                    "display_name": "Checkbox",
                    "description": "We are committed to protecting and respecting your privacy, and we'll only use your personal information to administer your account and to provide the products and services you requested from us, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below:",
                    "id": "DecisionBox1",
                    "key": "DecisionBox1",
                    "type": "input",
                    "variant": "checkbox",
                    "choices": [
                        { "value": "I agree to receive news from the UK Customer Experience Awards", "key": "I agree to receive news from the UK Customer Experience Awards" }
                    ],
                    "help" : "You can unsubscribe from this list at any time. By clicking submit below, you consent to allow Awards International to store and process the personal information submitted above to provide you with the content requested.",
                    "mandatory": false,
                },
                // {
                //     "key": "zf_referrer_name",
                //     "type": "input",
                //     "variant": "hidden",
                //     "initialValue": "",
                //     "mandatory": false,
                // },
                // {
                //     "key": "zf_redirect_url",
                //     "type": "input",
                //     "variant": "hidden",
                //     "initialValue": "",
                //     "mandatory": false,
                // },
                // {
                //     "key": "zc_gad",
                //     "type": "input",
                //     "variant": "hidden",
                //     "initialValue": "",
                //     "mandatory": false,
                // },
            ]
        }
    },
    // {
    //     "slug": "career-with-us",
    //     "name": "Career with Us",
    //     "formPerma": "https://forms.zohopublic.com/awardsinternational/form/AICareerwithus/formperma/9Yso5-F4eoJ8NEVzY_bHZXbVzK7C7ezTUUK0hRLuOZU/records",
    //     "thankYouMessage": `
    //         <h4>Thank you for registering your interest in the Awards International careers. </h4>
    //         <p>We look forward to providing you with valuable updates.</p>
    //         <p>
    //             Kind regards,<br />
    //             <strong>The Awards International Team</strong>
    //         </p>`,
    //     "definition": {
    //         "fields": [
    //             {
    //                 "display_name": "Name",
    //                 "key": "Name",
    //                 "type": "input",
    //                 "variant": "group",
    //                 "mandatory": true,
    //                 "fields": [
    //                     {
    //                         "display_name": "First Name",
    //                         "key": "Name_First",
    //                         "id": "firstName",
    //                         "type": "input",
    //                         "variant": "text",
    //                         "validation": [
    //                             { "type": "notEmpty", "message": " is mandatory."}
    //                         ],
    //                         "mandatory": true,
    //                     },
    //                     {
    //                         "display_name": "Last Name",
    //                         "key": "Name_Last",
    //                         "id": "lastName",
    //                         "type": "input",
    //                         "variant": "text",
    //                         "mandatory": true,
    //                         "validation": [
    //                             { "type": "notEmpty", "message": " is mandatory."},
    //                         ],
    //                     }
    //                 ]
    //             },
    //             {
    //                 "display_name": "Email",
    //                 "key": "Email",
    //                 "id": "email",
    //                 "type": "input",
    //                 "variant": "email",
    //                 "mandatory": true,
    //                 "validation": [
    //                     { "type": "notEmpty", "message": " is mandatory."},
    //                 ],
    //             },
    //             {
    //                 "display_name": "Phone",
    //                 "key": "PhoneNumber1",
    //                 "id": "phone",
    //                 "type": "input",
    //                 "variant": "text",
    //                 "mandatory": true,
    //                 "validation": [
    //                     { "type": "notEmpty", "message": " is mandatory."},
    //                 ],
    //             },
    //             {
    //                 "display_name": "Choose Your Interest",
    //                 "key": "Dropdown",
    //                 "id": "interestType",
    //                 "type": "select",
    //                 "variant": "",
    //                 "mandatory": true,
    //                 "validation": [
    //                     { "type": "notEmpty", "message": " is mandatory."},
    //                 ],
    //                 "choices": [
    //                     { "value": "Sales", "key": "Sales" },
    //                     { "value": "Marketing", "key": "Marketing" },
    //                     { "value": "Events", "key": "Events" },
    //                     { "value": "Web Development", "key": "Web Development" },
    //                     { "value": "Design", "key": "Design" },
    //                     { "value": "CXM", "key": "CXM" },
    //                     { "value": "CX Scoop", "key": "CX Scoop" },
    //                 ],
    //             },
    //             {
    //                 "display_name": "Checkbox",
    //                 "description": "We are committed to protecting and respecting your privacy, and we'll only use your personal information to administer your account and to provide the products and services you requested from us, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below:",
    //                 "id": "receiveNews",
    //                 "key": "DecisionBox",
    //                 "type": "input",
    //                 "variant": "checkbox",
    //                 "choices": [
    //                     { "value": "I agree to receive news from the Awards International.", "key": "I agree to receive news from the Awards International." }
    //                 ],
    //                 "help" : "You can unsubscribe from this list at any time. By clicking submit below, you consent to allow Awards International to store and process the personal information submitted above to provide you with the content requested.",
    //                 "mandatory": false,
    //             },
    //             {
    //                 "key": "SingleLine",
    //                 "type": "input",
    //                 "variant": "hidden",
    //                 "initialValue": "AI - Career with us",
    //                 "mandatory": false,
    //             },
    //             {
    //                 "key": "SingleLine1",
    //                 "type": "input",
    //                 "variant": "hidden",
    //                 "initialValue": "AI - Career with us",
    //                 "mandatory": false,
    //             },
    //             {
    //                 "key": "SingleLine2",
    //                 "type": "input",
    //                 "variant": "hidden",
    //                 "initialValue": "AI - Career with us",
    //                 "mandatory": false,
    //             },
    //         ]
    //     }
    // },
    {
        "slug": "plan2win",
        "name": "Plan2Win",
        "formPerma": "https://forms.zohopublic.com/awardsinternational/form/UKCXA25Plan2Win/formperma/BtQXp9bZrk8DO_iCyavWh1if_KHtOUF8Qjene6U5tNI/records",
        "thankYouMessage": `
            <h4>Thank you for signing up to explore the Plan2Win!</h4>
            <p>We look forward to providing you with valuable updates.</p>
            <p>
                Kind regards,<br />
                <strong>UK Customer Experience Awards Team</strong>
            </p>`,
        "definition": {
            "fields": [
                {
                    "display_name": "Name",
                    "key": "Name",
                    "type": "input",
                    "variant": "group",
                    "mandatory": true,
                    "fields": [
                        {
                            "display_name": "First Name",
                            "key": "Name_First",
                            "id": "firstName",
                            "type": "input",
                            "variant": "text",
                            "validation": [
                                { "type": "notEmpty", "message": " is mandatory."}
                            ],
                            "mandatory": true,
                        },
                        {
                            "display_name": "Last Name",
                            "key": "Name_Last",
                            "id": "lastName",
                            "type": "input",
                            "variant": "text",
                            "mandatory": true,
                            "validation": [
                                { "type": "notEmpty", "message": " is mandatory."},
                            ],
                        }
                    ]
                },
                {
                    "display_name": "Company",
                    "key": "SingleLine",
                    "id": "company",
                    "type": "input",
                    "variant": "text",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Job Title",
                    "id": "title",
                    "key": "SingleLine1",
                    "type": "input",
                    "variant": "text",
                    "mandatory": false,
                },
                {
                    "display_name": "Phone",
                    "key": "SingleLine2",
                    "id": "phone",
                    "type": "input",
                    "variant": "number",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Email",
                    "key": "Email",
                    "id": "email",
                    "type": "input",
                    "variant": "email",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Checkbox",
                    "description": "We are committed to protecting and respecting your privacy, and we'll only use your personal information to administer your account and to provide the products and services you requested from us, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below:",
                    "id": "DecisionBox1",
                    "key": "DecisionBox1",
                    "type": "input",
                    "variant": "checkbox",
                    "choices": [
                        { "value": "I agree to receive news from the UK Customer Experience Awards", "key": "I agree to receive news from the UK Customer Experience Awards" }
                    ],
                    "help" : "You can unsubscribe from this list at any time. By clicking submit below, you consent to allow Awards International to store and process the personal information submitted above to provide you with the content requested.",
                    "mandatory": false,
                }
                // {
                //     "key": "zf_referrer_name",
                //     "type": "input",
                //     "variant": "hidden",
                //     "initialValue": "",
                //     "mandatory": false,
                // },
                // {
                //     "key": "zf_redirect_url",
                //     "type": "input",
                //     "variant": "hidden",
                //     "initialValue": "",
                //     "mandatory": false,
                // },
                // {
                //     "key": "zc_gad",
                //     "type": "input",
                //     "variant": "hidden",
                //     "initialValue": "",
                //     "mandatory": false,
                // },
            ]
        }
    },
    {
        "slug": "plan2judge",
        "name": "Plan2Judge",
        "formPerma": "https://forms.zohopublic.com/awardsinternational/form/UKCXA25Plan2Judge/formperma/OFBseXzl8WuuIlTo-dKuwRNIwyjmlFm9-X3y2iOIHcg/records",
        "thankYouMessage": `
            <h4>Thank you for signing up to explore the Plan2Judge!</h4>
            <p>We look forward to providing you with valuable updates.</p>
            <p>
                Kind regards,<br />
                <strong>UK Customer Experience Awards Team</strong>
            </p>`,
        "definition": {
            "fields": [
                {
                    "display_name": "Name",
                    "key": "Name",
                    "type": "input",
                    "variant": "group",
                    "mandatory": true,
                    "fields": [
                        {
                            "display_name": "First Name",
                            "key": "Name_First",
                            "id": "firstName",
                            "type": "input",
                            "variant": "text",
                            "validation": [
                                { "type": "notEmpty", "message": " is mandatory."}
                            ],
                            "mandatory": true,
                        },
                        {
                            "display_name": "Last Name",
                            "key": "Name_Last",
                            "id": "lastName",
                            "type": "input",
                            "variant": "text",
                            "mandatory": true,
                            "validation": [
                                { "type": "notEmpty", "message": " is mandatory."},
                            ],
                        }
                    ]
                },
                {
                    "display_name": "Company",
                    "key": "SingleLine",
                    "id": "company",
                    "type": "input",
                    "variant": "text",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Job Title",
                    "id": "title",
                    "key": "SingleLine1",
                    "type": "input",
                    "variant": "text",
                    "mandatory": false,
                },
                {
                    "display_name": "Phone",
                    "key": "SingleLine2",
                    "id": "phone",
                    "type": "input",
                    "variant": "number",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Email",
                    "key": "Email",
                    "id": "email",
                    "type": "input",
                    "variant": "email",
                    "mandatory": true,
                    "validation": [
                        { "type": "notEmpty", "message": " is mandatory."},
                    ],
                },
                {
                    "display_name": "Checkbox",
                    "description": "We are committed to protecting and respecting your privacy, and we'll only use your personal information to administer your account and to provide the products and services you requested from us, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below:",
                    "id": "DecisionBox1",
                    "key": "DecisionBox1",
                    "type": "input",
                    "variant": "checkbox",
                    "choices": [
                        { "value": "I agree to receive news from the UK Customer Experience Awards", "key": "I agree to receive news from the UK Customer Experience Awards" }
                    ],
                    "help" : "You can unsubscribe from this list at any time. By clicking submit below, you consent to allow Awards International to store and process the personal information submitted above to provide you with the content requested.",
                    "mandatory": false,
                }
                // {
                //     "key": "zf_referrer_name",
                //     "type": "input",
                //     "variant": "hidden",
                //     "initialValue": "",
                //     "mandatory": false,
                // },
                // {
                //     "key": "zf_redirect_url",
                //     "type": "input",
                //     "variant": "hidden",
                //     "initialValue": "",
                //     "mandatory": false,
                // },
                // {
                //     "key": "zc_gad",
                //     "type": "input",
                //     "variant": "hidden",
                //     "initialValue": "",
                //     "mandatory": false,
                // },
            ]
        }
    }
]

const fieldTypes = [ { type: 'Email', value: 3 }, { type: 'Rich Text', value: 4 }, { type: 'Number', value: 5 }, { type: 'Decimal', value: 6 }, { type: 'Percent', value: 7 }, { type: 'Currency', value: 8 }, { type: 'Auto Number', value: 9 }, { type: 'Date', value: 10 }, { type: 'Date-time', value: 11 }, { type: 'Drop Down', value: 12 }, { type: 'Radio', value: 13 }, { type: 'Multi Select', value: 14 }, { type: 'Checkbox', value: 15 }, { type: 'Lookup	12 / 13 / 14 /', value: 15 }, { type: 'Decision Box', value: 16 }, { type: 'URL', value: 17 }, { type: 'Image', value: 18 }, { type: 'File Upload', value: 19 }, { type: 'Formula', value: 20 }, { type: 'Subform', value: 21 }, { type: 'Zoho CRM', value: 22 }, { type: 'Zoho CRM Link', value: 23 }, { type: 'Add Notes', value: 24 }, { type: 'Signature', value: 25 }, { type: 'Users', value: 26 }, { type: 'Phone', value: 27 }, { type: 'Section', value: 28 }, { type: 'Name', value: 29 }, { type: 'Address', value: 30 }, { type: 'Integration', value: 31 }, { type: 'Audio', value: 32 }, { type: 'Video', value: 33 }, { type: 'Time', value: 34 }, { type: 'OCR', value: 35 }, { type: 'Object Detection', value: 36 }, { type: 'Keyword Extraction', value: 37 }, { type: 'Sentiment Analysis', value: 38 }, { type: 'Prediction', value: 39 }, ]
export default forms
export { fieldTypes }