const Testimonial = ({ props }) => {
    return (
        <div className={`bg-white/30 p-8 relative mt-16 ${props.className}`}>
            <img className='absolute -top-16 left-0 w-32 ml-8 rounded-full' src={props.image} alt='' />
            <p className='pt-16'>{props.description}</p>
            <p className='font-semibold pt-4'>{props.title}</p>
        </div>
    )
}

export default Testimonial