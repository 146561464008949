/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'
import Bio from '../components/pages/about/bio'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'

/**
 * Images
 */
import alex from '../assets/images/pages/steering-council/Alex_Rutherford.jpg'
import jamie from '../assets/images/pages/steering-council/Jamie_Thorpe.jpg'
import helen from '../assets/images/pages/steering-council/Helen_Luty.jpeg'
import valentina from '../assets/images/pages/steering-council/Valentina_Postelnicu.jpg'
import peter from '../assets/images/pages/steering-council/Peter_Aitken.jpg'
import michelle from '../assets/images/pages/steering-council/Michelle_Ansell.jpg'
import kirstin from '../assets/images/pages/steering-council/Kirstin_Simons.jpg'
import linzi from '../assets/images/pages/steering-council/Linzi_Hindle.png'
import katie from '../assets/images/pages/steering-council/Katie_Stabler.jpeg'
import robert from '../assets/images/pages/steering-council/Robert_Dermott.jpg'
import janette from '../assets/images/pages/steering-council/Janette_Fiander.jpg'
import stuart from '../assets/images/pages/steering-council/Stuart_Wilson.jpg'
import caroline from '../assets/images/pages/steering-council/Caroline_King.jpeg'
import sally from '../assets/images/pages/steering-council/Sally_Anne_Hicks.jpg'
import alington from '../assets/images/pages/steering-council/Sally_Alington.png'
import kat from '../assets/images/pages/steering-council/Kat_Stroud.jpg'
import adrian from '../assets/images/pages/steering-council/Adrian_Stephenson.jpeg'



const SteeringCouncil = () => {

    useScrollTrigger('.reveal')
    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Steering Council</h1>
                </div>
            </section>
            <section className='bg-gradient-pastel text-ai-gray-900 pt-16'>
                <div>
                    <p className='reveal top'>Composed of leading CX professionals, our Steering Council provides guidance and support as we lay the foundations for another year of UK CXA®.</p>
                    <p className='reveal top'>Members are selected for a one-year term: they are all winners or judges at the UK Customer Experience Awards™.</p>
                    <p className='reveal top'>The Chair of the Steering Council for 2025 is Michelle Ansell.</p>
                    <p className='reveal top'>Take a look below to see who's helping us make 2025 the best UK CXA® yet!</p>
                </div>
            </section>

            <section className='bg-gray-100 text-ai-gray-900'>
                <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-32'>
                    <Bio props={{ title: 'Michelle Ansell', subtitle: 'Managing Partner', company: 'Douglas Jackson Limited' , image: michelle, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Alex Rutherford', subtitle: 'Senior CX Consultant', company: 'Skewb' , image: alex, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Jamie Thorpe', subtitle: 'Chief Experience Officer', company: 'Ipsos' , image: jamie, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Helen Luty', subtitle: 'Head of CX', company: 'Landmark Group' , image: helen, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Valentina Postelnicu', subtitle: 'Senior Director CX Advisory Services', company: 'Genesys' , image: valentina, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Peter Aitken', subtitle: 'Senior Director', company: 'Kantar' , image: peter, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Kirstin Simons', subtitle: 'Senior Director, Customer Success', company: 'NPSx by Bain & Company' , image: kirstin, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Linzi Hindle', subtitle: 'Director of CX', company: 'DHL' , image: linzi, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Katie Stabler', subtitle: 'Director', company: 'Cultivate' , image: katie, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Robert Dermott', subtitle: 'Client Solutions Director', company: 'CCA Recruitment Group' , image: robert, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Janette Fiander', subtitle: 'Snr Manager CX and Marketing', company: 'HSBC Innovation Banking' , image: janette, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Stuart Wilson', subtitle: 'Head of Sales & Marketing', company: 'Talkmobile' , image: stuart, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Caroline King', subtitle: 'Chief Customer Officer', company: 'Ageas UK' , image: caroline, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Sally-Anne Hicks', subtitle: 'VP Digital and Automation', company: 'Experian' , image: sally, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Sally Alington', subtitle: 'Founder & CEO', company: 'Ethos Farm' , image: alington, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Kat Stroud', subtitle: 'Senior CX Consultant', company: 'Forsta' , image: kat, className: 'reveal top !bg-white' }} />
                    <Bio props={{ title: 'Adrian Stephenson', subtitle: 'Digital Experience Lead', company: 'NatWest Group' , image: adrian, className: 'reveal top !bg-white' }} />
                </div>
            </section>

            <section className="bg-white text-ai-gray-900">
                                <div>
                                    <h2 className="bg-gradient-analagous-rose-triad-orange reveal top">Get in touch</h2>
                                    <div className="grid grid-cols-1 lg:grid-cols-2 pt-8">
                                        <div>
                                            <h4 className='reveal top font-semibold'>Neena Miskelly <br/> <span className="font-normal">Awards Consultant</span></h4>
                                            <p className='reveal top'></p>
                                        </div>
                                        <div>
                                            <p className="reveal top delay-300">
                                                Email: <Link className='mailto' to='mailto:neena@awardsinternational.com'>neena@awardsinternational.com</Link> <br/>
                                                Phone Number: +44 7383 329903
                                            </p>
                                        </div>
                                    </div>
                                    <div className='reveal top'>
                                        <button className='self-start'>
                                            <Link to='https://awardsinternational.zohobookings.com/#/neenamiskelly' target='_blank'>
                                                <div>Book a call</div>
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </section>

            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default SteeringCouncil