/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react'

/**
 * Components
 */

import Footer from '../components/footer'
import Slider from '../components/slider'
import PriceAccordion from '../components/prices-accordion'

/**
 * Hooks
 */

import useScrollTrigger from '../hooks/scroll-trigger.hook'


const EventDetails = () => {

     const [activeIndex, setActiveIndex] = useState(0);
    
        const handleToggle = (index) => {
            setActiveIndex(index === activeIndex ? null : index);
        };
    

    useScrollTrigger('.reveal')

    return (
        <>
            <section className='bg-white py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Event Details</h1>
                </div>
            </section>


            <section className='bg-gradient-pastel text-ai-gray-900'>
                    <div>
                        <h3 className="self-start reveal top">Build-up to the Awards</h3>
                        <p className='reveal top'>Key facts:</p>
                    </div>
            
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 mt-6'>
                        <div>
                            <ul className='list'>
                                <li className='reveal top'><b>Estimated Attendees:</b> 400</li>
                                <li className='reveal top'><b>Date & Timings:</b> 16th Oct 2025, 12 PM – 4 PM</li>
                            </ul>
                        </div>
                        <div>
                            <ul className='list'>
                                <li className='reveal top delay-300'><b>Dress Code:</b> Business Casual</li>
                                <li className='reveal top delay-300'><b>Venue:</b> Wembley Stadium, Great Hall</li>
                            </ul>
                        </div>
                    </div>    
                    <div>
                        <p className='reveal top'><b>The Build-Up to the Awards</b> is a must-attend part of the UKCXA™ experience, offering exclusive insights and inspiration. Hear from industry leaders as they unveil the latest CX trends, research, and innovations. Dive into the future of CX with engaging panel discussions featuring this year’s finalists, and take part in interactive sessions where you can ask questions, vote in polls, and share conclusions. This is your chance to connect with like-minded professionals over a delicious lunch and drinks, all in the iconic Wembley Stadium. Don’t miss this dynamic event designed to energise and prepare you for the Awards celebration!</p>
                    </div>

                    <div className='max-w-[100vw] mt-20'>
                        <Slider props={{
                            slidesPerView: 'auto',
                            centeredSlides: true,
                            autoplay: true,
                            loop: true,
                            className: 'max-w-[100%]',
                            slideWrapperClassName: 'max-w-[100%] xl:max-w-[1128px] 2xl:max-w-[1290px] px-0 2xl:px-0',
                            slideClassName: 'rounded-3xl'
                        }}>
                            <img src='./images/slider/cxa/panel-discussion.png' alt='' />
                            <img src='./images/slider/cxa/drink-reception.png' alt='' />
                            <img src='./images/slider/cxa/drink-reception-1.png' alt='' />
                        </Slider>
                    </div>
            </section>

            <section className='bg-gray-100 text-ai-gray-900'>
                    <div>
                        <h2 className="bg-gradient-analagous-rose-triad-orange self-start reveal top">Awards Ceremony</h2>
                        <p className='reveal top'>Key facts:</p>
                    </div>
            
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 mt-6'>
                        <div>
                            <ul className='list'>
                                <li className='reveal top'><b>Estimated Attendees:</b> 900</li>
                                <li className='reveal top'><b>Date & Timings:</b> 16th Oct 2025; 6 PM – 1 AM</li>
                            </ul>
                        </div>
                        <div>
                            <ul className='list'>
                                <li className='reveal top delay-300'><b>Dress Code:</b> Black Tie or Cocktail Dress</li>
                                <li className='reveal top delay-300'><b>Venue:</b> Wembley Stadium, Great Hall</li>
                            </ul>
                        </div>
                    </div>    
                    <div>
                        <p className='reveal top'>Step onto the red carpet and enjoy pre-dinner drinks at the iconic Wembley Stadium, where the excitement is palpable, and anticipation fills the air. Hosted by the dynamic duo Emma Dark and Ian Golding, the awards ceremony takes centre stage with a glamorous setup, live band, and dazzling ambiance.</p>

                        <p className='reveal top'>As the moment of truth arrives, the atmosphere is electric — everyone eagerly awaits the winners' announcements. After celebrating the well-deserved victories, it’s time to hit the dance floor and toast to your team’s incredible achievements. This unforgettable evening is the perfect finale to an extraordinary day!</p>
                    </div>

                    <div className='max-w-[100vw] mt-20'>
                        <Slider props={{
                            slidesPerView: 'auto',
                            centeredSlides: true,
                            autoplay: true,
                            loop: true,
                            className: 'max-w-[100%]',
                            slideWrapperClassName: 'max-w-[100%] xl:max-w-[1128px] 2xl:max-w-[1290px] px-0 2xl:px-0',
                            slideClassName: 'rounded-3xl'
                        }}>
                            <img src='./images/slider/cxa/UKCXA24-ceremony.png' alt='' />
                            <img src='./images/slider/cxa/UKCXA24-ceremony-1.png' alt='' />
                            <img src='./images/slider/cxa/UKCXA24-ceremony-3.png' alt='' />
                            <img src='./images/slider/cxa/UKCXA24-ceremony-4.png' alt='' />
                        </Slider>
                    </div>
            </section>


            <section className='bg-white text-ai-gray-900'>
                <div>
                    <h2 className="bg-gradient-analagous-rose-triad-orange self-start reveal top">Booking Fees</h2>

                    <PriceAccordion 
                    heading="Early Bird" 
                    date="5th Sep" 
                    firstBoxHeading="Standard Table (of 10 seats)"
                    firstBoxPrice="£5,500" 
                    secondBoxHeading="Premium Table (of 10 seats)"
                    secondBoxPrice="£6,990"  
                    toggle={() => handleToggle(0)}
                    open={activeIndex === 0}/>

                    <PriceAccordion 
                    heading="Late Bird" 
                    date="2nd Oct" 
                    firstBoxHeading="Standard Table (of 10 seats)"
                    firstBoxPrice="£7,000" 
                    secondBoxHeading="Premium Table (of 10 seats)"
                    secondBoxPrice="£8,390" 
                    // thirdBoxHeading="4-entry pack"
                    // thirdBoxPrice="£1,799" 
                    toggle={() => handleToggle(1)}
                    open={activeIndex === 1}/>
 


                    <div>
                        <p><b>All prices net of VAT</b></p>

                        <h4 className='reveal top font-semibold mt-8'>Table Packages</h4>
                    </div>

                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 mt-6'>
                        <div>
                            <p className='reveal top'><b>Standard Table</b></p>
                            <ul className='list'>
                                <li className='reveal top'>Best positioned spots in the ballroom</li>
                                <li className='reveal top'>Opportunity to organise the seating plan for your team</li>
                                <li className='reveal top'>Half a bottle of wine per person</li>
                                <li className='reveal top'>Two jars of juice, still and sparkling water</li>
                                <li className='reveal top'>Prominent branding on the table (branded centrepiece)</li>
                                <li className='reveal top'>Three-course gala dinner</li>
                                <li className='reveal top'>Admission to attend the Build-Up to the Awards</li>
                                <li className='reveal top'>Food and drinks during the Build-Up to the Awards</li>
                                <li className='reveal top'>Receive your trophy if your company wins</li>
                                <li className='reveal top'>Receive personalised winners logos if your company wins</li>
                                <li className='reveal top'>Featured in <i>Customer Experience Magazine</i> to showcase your success</li>
                            </ul>
                        </div>
                        <div>
                            <p className='reveal top delay-300'><b>Premium Table (limited to 8 tables)</b></p>
                            <ul className='list'>
                                <li className='reveal top delay-300'>All benefits of the Standard Table</li>
                                <li className='reveal top delay-300'>Table positioned in the first two rows in front of the stage</li>
                                <li className='reveal top delay-300'>Upgrade in drinks with three bottles of champagne and a bucket of beer</li>
                            </ul>
                        </div>
                    </div>   

                    <div className='reveal top'>
                        <button className='self-start'>
                            <Link to={`https://awintern.com/`} target='_blank'>
                                <div>Book Now</div>
                            </Link>
                        </button>
                    </div>

                    <div>
                        <p>For additional information about booking, please speak to the consultant.</p>
                    </div>
                    
                </div>
            </section>

            <section className="bg-none bg-gray-100 text-ai-gray-900">
                <div>
                    <h2 className="bg-gradient-analagous-rose-triad-orange reveal top">Get in touch</h2>
                    <div className="grid grid-cols-1 lg:grid-cols-2 pt-8">
                        <div>
                            <h4 className='reveal top font-semibold'>Neena Miskelly <br/> <span className="font-normal">Awards Consultant</span></h4>
                            <p className='reveal top'></p>
                        </div>
                        <div>
                            <p className="reveal top delay-300">
                                Email: <Link className='mailto' to='mailto:neena@awardsinternational.com'>neena@awardsinternational.com</Link> <br/>
                                Phone Number: +44 7383 329903
                            </p>
                        </div>
                    </div>
                    <div className='reveal top'>
                        <button className='self-start dark'>
                            <Link to='https://awardsinternational.zohobookings.com/#/neenamiskelly' target='_blank'>
                                    <div>Book a call</div>
                            </Link>
                        </button>
                    </div>
                </div>
            </section>


            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default EventDetails