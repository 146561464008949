/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


const KitResources = () => {

    useScrollTrigger('.reveal')
    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Plan2Win®</h1>
                </div>
            </section>
            <section className='bg-gradient-pastel text-ai-gray-900 pt-16'>
                <div>
                    <p className='reveal top'>Congratulations on accessing the  Plan2Win® - your exclusive gateway to premium insights and award-winning excellence. This carefully curated resource is designed to inspire, guide, and equip you with everything you need to craft a compelling entry and elevate your journey in the UK Customer Experience Awards and beyond.</p>
                    <p className='reveal top'>Start your journey by exploring Plan2Win, your go-to resource for navigating the UKCXA™ journey with ease. To access even more valuable insights and exclusive content, get in touch with your awards consultant and take the next step toward submitting your nomination.</p>
                </div>
            </section>

            <section className='bg-white text-ai-gray-900'>
                <div>
                    <h2 className='reveal top'>Plan2Win®</h2>        
                    <p className='reveal-top mb-10'>Your step-by-step guide to mastering the awards process, packed with tips, tools, and best practices.</p>
                    
                    <iframe src="https://publuu.com/flip-book/45595/1760788/page/1?embed" width="100%" height="700" frameborder="0" allow="clipboard-write; autoplay; fullscreen" class="publuuflip"></iframe>

                     <div className='reveal top mt-10'>
                        <button className='dark self-start'>
                            <Link to={`https://awardsinternational.zohobookings.com/#/neenamiskelly`} target='_blank'>
                                <div>Want More?</div>
                            </Link>
                        </button>

                        <p>Contact your Awards Consultant, Neena Miskelly.</p>
                    </div>
                </div>
            </section>

            <section className="bg-none bg-gray-100 text-ai-gray-900">
                            <div>
                                <h2 className="bg-gradient-analagous-rose-triad-orange reveal top">Get in touch</h2>
                                <div className="grid grid-cols-1 lg:grid-cols-2 pt-8">
                                    <div>
                                        <h4 className='reveal top font-semibold'>Neena Miskelly <br/> <span className="font-normal">Awards Consultant</span></h4>
                                        <p className='reveal top'></p>
                                    </div>
                                    <div>
                                        <p className="reveal top delay-300">
                                            Email: <Link className='mailto' to='mailto:neena@awardsinternational.com'>neena@awardsinternational.com</Link> <br/>
                                            Phone Number: +44 7383 329903
                                        </p>
                                    </div>
                                </div>
                                <div className='reveal top'>
                                    <button className='self-start dark'>
                                        <Link to='https://awardsinternational.zohobookings.com/#/neenamiskelly' target='_blank'>
                                                <div>Book a call</div>
                                        </Link>
                                    </button>
                                </div>
                            </div>
                        </section>


            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default KitResources