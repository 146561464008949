/**
 * Core
 */
import React from 'react'

/**
 * Components
 */
import Footer from '../components/footer'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


const Plan2Judge = () => {

    useScrollTrigger('.reveal')
    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>Plan2Judge</h1>
                </div>
            </section>
            <section className='bg-gradient-pastel text-ai-gray-900 pt-16'>
                <div>
                    <p className='reveal top'>Welcome to the Plan2Judge page! Here, you’ll find everything you need to know about becoming a judge for the UK Customer Experience Awards. This comprehensive guide outlines all the benefits of judging, including exclusive access to the Judge Club, personalised promo cards, and the chance to gain a judges' certificate and feedback report if you excel in your role. We’ll also provide clear guidance on how to assess entries effectively, ensuring you make the most of this rewarding experience. Join us and be part of the process that recognises and celebrates the best in customer experience!</p>
                </div>
            </section>

            <section className='bg-white text-ai-gray-900'>
                <div>
                    <h2 className='reveal top'>Plan2Judge™</h2>        
                    <p className='reveal-top mb-10'>Your complete guide to becoming a top judge at the UKCXA, with exclusive benefits and expert judging tips.</p>
                    
                    <iframe src="https://publuu.com/flip-book/45595/1832246/page/1?embed" width="100%" height="700" scrolling="no" frameborder="0" allow="clipboard-write; autoplay; fullscreen" class="publuuflip"></iframe>


                    {/* <h2 className='reveal top mt-20'>Judge Feedback Report & Certificate</h2>
                    <p className='reveal top'>Receive detailed feedback on your judging performance and earn a prestigious certification recognising your expertise and contribution to UKCXA.</p>
                    <p className='reveal top mb-10'>One Feedback Report is sufficient to be presented in this section.</p>

                    <iframe src="https://publuu.com/flip-book/45595/1723259/page/1?embed" width="100%" height="700" scrolling="no" frameborder="0" allow="clipboard-write; autoplay; fullscreen" title='case-studies' class="publuuflip"></iframe> */}
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default Plan2Judge