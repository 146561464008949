import { Link } from 'react-router-dom'
import Footer from '../components/footer'

import { ReactComponent as Logo } from '../assets/images/components/menu/UKCXA25_logo.svg'

const Awards = () =>
    <>
        <section className='bg-ai-gray-900 p-0'>
            <div className='items-center relative'>
                {/* <div className='absolute w-full h-full z-[100] bg-gradient-to-tr from-red-700 to-blue-800 bg-[position:_0%_0%] hover:bg-[position:_100%_100%] bg-[size:_200%] h-40 w-40 transition-all duration-500'></div> */}
                {/* <h1 className='bg-gradient-analagous-rose-triad-orange'>Awards</h1> */}
                <Logo className='h-36' />
                {/* <h4 className='text-ai-grey-50 pt-0'>Unrivalled rigour, service and professionalism.</h4> */}
            </div>
        </section>
        <section className='bg-gradient-pastel'>
            <div>
                
            </div>
        </section>
        <section className='pb-0 border-t border-t-ai-gray-800'>
            <Footer />
        </section>
    </>

export default Awards