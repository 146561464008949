import CountUp from "react-countup"

const Stats = ({ props }) =>
    <>
    <div className={`rounded-xl bg-ai-gray-900 p-8 mt-8 max-w-[600px] sm:max-w-auto mx-auto ${props.className}`}>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 mx-auto relative'>
            <div className='stats'><CountUp end={71} duration={15} delay={8} className="bg-gradient-pastel"/>NPS</div>
            <div className='stats'><CountUp end={80} duration={15} delay={8} className="bg-gradient-pastel"/>Participants would recommend UKCXA to their partners</div>
            <div className='stats'><CountUp end={900} duration={15} delay={8} className="bg-gradient-pastel"/>Attendees at the gala event in 2024</div>
            <div className='stats'><CountUp end={1300} duration={15} delay={8} className="bg-gradient-pastel"/>Finalists since 2010</div>
            <div className='stats'><CountUp end={1650} duration={15} delay={8} className="bg-gradient-pastel"/>Judges since 2010</div>
            <div className='stats'><CountUp end={6} duration={15} delay={8} className="bg-gradient-pastel"/>Years in a row, Awards Trust Mark - Outstanding</div>
            <div className='stats'><CountUp end={16} duration={15} delay={8} className="bg-gradient-pastel"/>Consecutive years of the UK Customer Experience Awards</div>
            <div className='stats'><CountUp end={3190} duration={15} delay={8} className="bg-gradient-pastel"/>Case Studies presented</div>
        </div>

        {/* <div className='stats mt-8'><span className='bg-gradient-pastel'>6</span>years in a row, our awards process has been recognised with the Outstanding mark of achievement by the Independent Awards Standards Council</div> */}
    </div>
    </>

export default Stats