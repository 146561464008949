/**
 * Core
 */
import { Link } from 'react-router-dom'

/**
 * Utils
 */
import { itemsWithHome } from '../config/menu'

const Footer = () => {
    return (
        <>
            <div className='grid grid-cols-1 sm:grid-cols-3 gap-8 text-ai-gray-300'>
                <ul className='col-span-2 grid grid-cols-1 sm:grid-cols-2 gap-x-8'>
                    {
                        itemsWithHome.map((item, key) => <li key={key} className='pt-1'><a href={item.link} className='transition-all duration-300 hover:text-ai-gray-400'>{item.title}</a></li>)
                    }
                </ul>
                
                <div className='flex items-center gap-4 self-start'>
                    <Link to="https://www.facebook.com/AwardsInter/" className='w-7' target='_blank'><img src={`/images/icons/facebook.svg`}  alt=''/></Link>
                    {/* <Link to="https://www.youtube.com/@awardsinternational4131" className='w-7' target="_blank" rel="noopener noreferrer"><img src={`/images/icons/youtube.svg`} /></Link> */}
                    <Link to="https://www.instagram.com/awards_international/" className='w-7' target="_blank" rel="noopener noreferrer"><img src={`/images/icons/instagram.svg`} alt='' /></Link>
                    <Link to="https://twitter.com/ukcxawards" className='w-7' target='_blank'><img src={`/images/icons/x.svg`} alt='' /></Link>
                    <Link to="https://www.linkedin.com/company/279694/" className='w-7' target="_blank" rel="noopener noreferrer"><img src={`/images/icons/linkedin.svg`}  alt='' /></Link>
                </div>
            </div>
            <div className='flex justify-center items-center py-8 text-ai-gray-300'>
                <ul className=''>
                    <li className=''><Link to='/terms-and-conditions' className='transition-all duration-300 hover:text-ai-gray-400'>Terms and Conditions</Link></li>
                    <li className=''><Link to='https://awardsinternational.com/privacy-policy' target='_blank' className='transition-all duration-300 hover:text-ai-gray-400'>Privacy Policy</Link></li>
                    <li className='pt-1 pb-8'><Link to='/terms-of-payment' className='transition-all duration-300 hover:text-ai-gray-400'>Terms of Payment</Link></li>
                </ul>
                <p className='text-center'>
                    {/* Acacia Farm, Lower Road, Royston, SG8 0EE, UNITED KINGDOM <br/> */}
                    © Awards International. All rights reserved.
                </p>
            </div>
        </>
    )
}

export default Footer