const shuffle = (arr) => [...arr].sort( () => .5 - Math.random() )
const random = (min, max) => Math.floor(Math.random() * (max - min)) + min

const InfiniteLoopSlider = ({children, duration, reverse = false}) => {
    return (
      <div className='loop-slider' style={{
          '--duration': `${duration}ms`,
          '--direction': reverse ? 'reverse' : 'normal'
        }}>
        <div className='flex w-fit animate-loop'>
          {children}
          {children}
        </div>
      </div>
    );
}

const Tag = ({ image, folder }) => (
    <div className='flex items-center gap-2 text-[#e2e8f0] px-4 py-0 mr-4'><img src={folder + image} alt='' className='min-w-[16rem]' /></div>
)

const ImageScroller = ({props}) => 
    <div className='max-w-[100vw] flex shrink-0 flex-col relative py-2 overflow-hidden mt-12'>
        {[...new Array(1)].map((_, i) => (
            <InfiniteLoopSlider key={i} duration={4000*props.images.length} reverse={props.reverse}>
                {
                    shuffle(props.images)
                    .map(tag => (
                        <Tag image={tag} key={tag} folder={props.folder} />
                    ))
                }
            </InfiniteLoopSlider>
        ))}
        {/* <div className='pointer-events-none bg-[linear-gradient(90deg, #1e293b, transparent 30%, transparent 70%, #1e293b)] absolute inset-0'></div> */}
    </div>

export default ImageScroller