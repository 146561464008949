import { Link } from "react-router-dom"

const Webinars = ({ img, heading, desc, link }) => {
  return (
        <div className="mt-10 shadow-md rounded-2xl">
            <img src={img} alt="webinar-img" />
            <div className="p-10">
            <h3 className="text-cxa_primary mt-4">{heading}</h3>
            <p>{desc}</p>

            <div>
                <button className='self-start'>
                    <Link to={link} target="_blank">
                        <div>Register Now</div>
                    </Link>
                </button>
            </div>
            </div>
        </div>
  )
}

export default Webinars
