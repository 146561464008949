/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'
import PostSkeleton from '../components/pages/top-stories/post-skeleton'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'

/**
 * graphql
 */
import { useQuery } from '@apollo/client'
import { GET_POSTS_BY_CATEGORY_WITH_PAGING } from '../utils/gql'


const NewsAndInsight = () => {

    useScrollTrigger('.reveal')

    const { data, loading, error, fetchMore } = useQuery(GET_POSTS_BY_CATEGORY_WITH_PAGING, {
        variables: { first: 6, after: null, category: 'Customer Experience' },
        notifyOnNetworkStatusChange: true,
    })

    if (error) {
        return <p>Sorry, an error happened. Reload Please</p>
    }

    const loadMore = () => {
        fetchMore({
            variables: { after: data?.posts?.pageInfo?.endCursor, first: 4 },
            updateQuery: (prevResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prevResult
                return {
                    posts: {
                        ...fetchMoreResult.posts,
                        edges: [
                            ...prevResult.posts.edges,
                            ...fetchMoreResult.posts.edges,
                        ],
                    },
                }
            },
        })
    }
    
    
    return (
        <>
            <section className='bg-white text-ai-gray-900 py-16'>
                <div>
                    <h1 className='self-center bg-gradient-analagous-rose-triad-orange'>News and Insight</h1>
                </div>
            </section>
            <section className='bg-gray-100 text-ai-gray-900'>
                <div>
                    <p className='reveal top'><b>Customer Experience Magazine (CXM)</b> is the world’s first publication dedicated exclusively to CX and EX topics. Owned by Awards International, CXM complements the UKCXA™ by providing participants a platform to share their success stories. Featuring premium content, including daily news, industry insights, trends, thought leadership, and global case studies, CXM is your go-to source for all things customer and employee experience. Stay informed, inspired, and ahead of the curve with CXM’s expert-driven content.</p>
                </div>
            </section>

            <section className='bg-white text-ai-gray-900 pt-10'>
                <div>
                {
                        (!data && loading) ?
                        <>
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4'>
                                <PostSkeleton />
                                <PostSkeleton />
                            </div>
                            <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 mt-4'>
                                <PostSkeleton />
                                <PostSkeleton />
                                <PostSkeleton />
                                <PostSkeleton />
                            </div>
                        </> :
                        <>
                            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4'>
                                {
                                    data.posts.edges.map((edge) => edge.node).slice(0,2).map((post, i) => {
                                        return (
                                            <div key={ post.id } className='bg-white'>
                                                <img src={ post?.featuredImage?.node?.sourceUrl } className='object-cover w-full h-72' alt=''/>
                                                <h5 className='text-xl px-4 pt-4 leading-6'><Link to={`/top-story/${post?.slug}`}>{ post?.title }</Link></h5>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='grid grid-cols-1 lg:grid-cols-4 gap-4 mt-4'>
                                {
                                    data.posts.edges.map((edge) => edge.node).slice(2).map((post, i) => {
                                        return (
                                            <div key={ post.id } className='bg-white'>
                                                <img src={ post?.featuredImage?.node?.sourceUrl } className='object-cover w-full h-36' alt=''/>
                                                <h5 className='text-xl px-4 pt-4 leading-6'><Link to={`/top-story/${post?.slug}`}>{ post?.title }</Link></h5>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {
                                data?.posts?.pageInfo?.hasNextPage && <button className='self-center' onClick={loadMore}><div>Load more</div></button>
                            }
                        </>
                    }
                </div>
            </section>

            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

export default NewsAndInsight