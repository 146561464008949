/**
 * Core
 */
import React from 'react'
import { Link, useParams } from 'react-router-dom'

/**
 * Components
 */
import Footer from '../components/footer'
import Spinner from '../components/pages/top-stories/spinner'
import {default as Top} from '../components/pages/home/top-story'

/**
 * graphql
 */
import { useQuery } from '@apollo/client'
import { GET_POST_BY_SLUG, GET_POSTS_BY_CATEGORY_WITH_PAGING } from '../utils/gql'

const TopStory = () => {
    let { slug } = useParams()

    const { data, loading, error } = useQuery(GET_POST_BY_SLUG, {
        variables: { slug }
    })    

    const latest = useQuery(GET_POSTS_BY_CATEGORY_WITH_PAGING, {
        variables: { first: 6, after: null, category: 'Customer Experience' },
        notifyOnNetworkStatusChange: true,
    })
    
    if (error) {
        return <p>Sorry, an error happened. Reload Please</p>
    }
    
    return (
        <article>
            {
                data &&
                <>
                    <title>{data.post.seo.title}</title>
                    <meta name="description" content={data.post.seo.metaDesc} />
                    <meta property="og:title" content={data.post.seo.opengraphTitle} />
                    <meta property="og:url" content={data.post.seo.opengraphUrl} />
                    <meta property="og:description" content={data.post.seo.opengraphDescription} />
                    <meta property="og:type" content={data.post.seo.opengraphType} />
                    <meta property="og:image" content={data.post.seo.opengraphImage.sourceUrl} />
                </>
            }
            <section className='bg-gray-100 py-16'>
                <div className='items-center'>
                    {
                        (!data && loading) ?
                        <>
                            <Spinner />
                        </> :
                        <h1 className='bg-gradient-analagous-rose-triad-orange text-4xl lg:text-6xl'>{ data.post.title }</h1>                        
                    }
                </div>
            </section>
            <section className='bg-white post'>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-x-8'>
                    
                    <div className='md:col-span-3 content'>
                        {
                            (!data && loading) ?
                            <>
                                <Spinner />
                            </> :
                            <div>
                                <img src={ data.post?.featuredImage?.node?.sourceUrl } className='w-full' alt='' />
                                <div className='text-ai-gray-900' dangerouslySetInnerHTML={{ __html: data.post?.content }}></div>
                            </div>
                        }
                    </div>
                    <aside className="self-start text-ai-gray-900 lg:sticky top-0 lg:top-24 lg:col-span-1">
                        {
                            (!latest.data && latest.loading) ?
                            <>
                                <Spinner />
                            </> :
                            <>
                                <h3 className='bg-clip-text !text-transparent bg-gradient-analagous-rose-triad-orange self-star pb-4'>Latest Articles</h3>
                                <ul className='list'>
                                    {                                    
                                        latest.data.posts.edges.map((edge) => edge.node).map((post, i) => {
                                            return (                                            
                                                <li key={i} className='hover:border-l-ai-analagous-rose-500 hover:text-ai-analagous-rose-500 transition-all duration-300'><Link to={`/top-story/${post?.slug}`}>{ post?.title }</Link></li>
                                            )
                                        })
                                    }
                                </ul>
                            </>
                        }
                    </aside>
                </div>
            </section>
            <section className='bg-gray-100 py-16'>
                <div>
                    {
                        (!data && loading) ?
                        <>
                            <Spinner />
                        </> :
                        data.post.tags.edges.map(edge => edge.node.posts.nodes.filter(post => post.id !== data.post.id)).flat(1).length &&
                        <>
                            <h3 className='bg-clip-text !text-transparent bg-gradient-analagous-rose-triad-orange self-start pb-12'>Related Articles</h3>
                            <div className='grid grid-cols-1 sm:grid-cols-3 gap-4'>
                                {                        
                                    data.post.tags.edges.map(edge => edge.node.posts.nodes.filter(post => post.id !== data.post.id)).flat(1).slice(0,3).map((post, i) => 
                                        <Top key={i} title={post.title} slug={post.slug} description={post.excerpt} image={post?.featuredImage?.node?.sourceUrl} excerpt={false} more={false} rounded={true} />
                                    )
                                }
                            </div>
                        </>
                    }
                </div>
            </section>
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </article>
    )
}

export default TopStory