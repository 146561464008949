
const items = [
    { title: 'About', link: '/how-to-enter' },
    { title: 'Plan2Win', link: '/plan2win' },
    { title: 'Categories', link: '/categories' },
    { title: 'Judging', link: '/apply-to-judge' },
    { title: 'Event Details', link: '/book-now' },
    { title: 'Sponsors and Partners', link: '/sponsors-and-partners' },
    { title: 'News and Insight', link: '/news-and-insight' },
    { title: 'Meet the Judges', link: '/meet-the-judges' },
    { title: 'Steering Council', link: '/steering-council' },
    /* { title: 'Media', link: '/media' }, */
]

const itemsWithHome = [
    { title: 'Home', link: '/' },
    { title: 'About', link: '/how-to-enter' },
    { title: 'Plan2Win', link: '/plan2win' },
    { title: 'Categories', link: '/categories' },
    { title: 'Judging', link: '/apply-to-judge' },
    { title: 'Event Details', link: '/book-now' },
    { title: 'Sponsors and Partners', link: '/sponsors-and-partners' },
    { title: 'News and Insight', link: '/news-and-insight' },
    { title: 'Meet the Judges', link: '/meet-the-judges' },
    { title: 'Steering Council', link: '/steering-council' },
    /* { title: 'Media', link: '/media' }, */
]

const itemsWithDropdown = [
    {
        title: 'Enter',
        dropdown: [
            {
              item: 'Enter the Awards',
              link: '/how-to-enter'  
            },
            {
              item: 'Categories',
              link: '/categories'  
            },
            {
              item: 'Plan2Win',
              link: '/plan2win'  
            }
        ] 
    },
    {
        title: 'Judging Process',
        dropdown: [
            {
              item: 'Apply to Judge',
              link: '/apply-to-judge'  
            },
            {
              item: 'Meet the Judges',
              link: '/meet-the-judges'  
            },
        ] 
    },
    // {
    //     title: 'Partners and Sponsors',
    //     link: '/sponsors-and-partners'
    // },
    // {
    //     title: 'Event Details',
    //     link: '/book-now'
    // },
    {
        title: 'More',
        dropdown: [
            {
              item: 'Steering Council',
              link: '/steerin-council'  
            },
            {
              item: 'News and insights',
              link: '/news-and-insights'  
            },
        ] 
    }
]

export default items
export { itemsWithHome }
export {itemsWithDropdown}