const root = 'https://cxa.co.uk'

const routes = [
    {
        'path': '',
        'props': {
            'name': 'Home',
            'title': 'UK Customer Experience Awards | Home',
            'description': '',
            'og:type': 'website',
            'og:url': `${root}/`,
            'og:image:secure_url': `${root}/images/og/home.jpg`,
            'og:image': `${root}/images/og/home.jpg`
        }
    },
    {
        'path': 'events',
        'props': {
            'name': 'Events',
            'title': 'UK Customer Experience Awards | Events',
            'description': '',
            'og:type': 'website',
            'og:url': `${root}/events`,
            'og:image:secure_url': `${root}/images/og/events.png`,
            'og:image': `${root}/images/og/events.png`
        }
    },
    {
        'path': 'get-started',
        'props': {
            'name': 'Get Started',
            'title': 'UK Customer Experience Awards | Get Started',
            'description': '',
            'og:type': 'website',
            'og:url': `${root}/get-started`,
            'og:image:secure_url': `${root}/images/og/get-started.png`,
            'og:image': `${root}/images/og/get-started.png`
        }
    },
    {
        'path': 'about-us',
        'props': {
            'name': 'About Us',
            'title': 'UK Customer Experience Awards | About Us',
            'description': '',
            'og:type': 'website',
            'og:url': `${root}/about-us`,
            'og:image:secure_url': `${root}/images/og/about-us.png`,
            'og:image': `${root}/images/og/about-us.png`
        }
    },
    {
        'path': 'top-stories',
        'props': {
            'name': 'Top Stories',
            'title': 'UK Customer Experience Awards | Top Stories',
            'description': '',
            'og:type': 'website',
            'og:url': `${root}/top-stories`,
            'og:image:secure_url': `${root}/images/og/top-stories.png`,
            'og:image': `${root}/images/og/top-stories.png`
        }
    },
    {
        'path': 'the-judge-club',
        'props': {
            'name': 'The Judge Club',
            'title': 'UK Customer Experience Awards | The Judge Club',
            'description': '',
            'og:type': 'website',
            'og:url': `${root}/the-judge-club`,
            'og:image:secure_url': `${root}/images/og/the-judge-club.png`,
            'og:image': `${root}/images/og/the-judge-club.png`
        }
    },
    {
        'path': 'privacy-policy',
        'props': {
            'name': 'Privacy Policy',
            'title': 'UK Customer Experience Awards | Privacy Policy',
            'description': '',
            'og:type': 'website',
            'og:url': `${root}/privacy-policy`,
            'og:image:secure_url': `${root}/images/og/privacy-policy.png`,
            'og:image': `${root}/images/og/privacy-policy.png`
        }
    },
    {
        'path': 'terms-of-use',
        'props': {
            'name': 'Terms of Use',
            'title': 'UK Customer Experience Awards | Terms of Use',
            'description': '',
            'og:type': 'website',
            'og:url': `${root}/terms-of-use`,
            'og:image:secure_url': `${root}/images/og/privacy-policy.png`,
            'og:image': `${root}/images/og/privacy-policy.png`
        }
    },
    {
        'path': 'categories',
        'props': {
            'name': 'Categories',
            'title': 'UK Customer Experience Awards | Categories',
            'description': '',
            'og:type': 'website',
            'og:url': `${root}/categories`,
            'og:image:secure_url': `${root}/images/og/events.png`,
            'og:image': `${root}/images/og/events.png`
        }
    },
]

const getMeta = (route) => {
    const meta = routes.find(r => r.path == route)
    return meta
}

export default routes
export { getMeta }