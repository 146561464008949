/**
 * Core
 */
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Components
 */
import Stats from '../components/pages/home/stats'
import Slider from '../components/slider'
import Footer from '../components/footer'
import ImageScroller from '../components/image-scroller'

/**
 * Hooks
 */
import useScrollTrigger from '../hooks/scroll-trigger.hook'


// Image
import miniLogo from '../assets/images/other/CXA_sym.svg'


const Main = () => {
    useScrollTrigger('.reveal')

    const scrollingLogos = ['hsbc.svg', 'cisco.svg', 'vodafone.svg', 'bt.svg', 'experian.svg', 'octopus_energy.svg', 'fred_olsen_cruise_lines.svg', 'co_op_insurance.svg', 'ageas.svg', 'ovo_energy.svg', 'dhl_supply_chain.svg', 'concentrix.svg', 'ala_insurance_brokers.svg', 'octopus_electric_vehicles.svg', 'cadent_gas.svg', 'pension_insurance_corporation.svg', 'natwest.svg', 'scottish_power.svg', 'asos.svg', 'landmark_information_group.svg', 'london_luton_airport.svg', 'fidelity_international.svg']

    const scrollingLogos1 = ['lv_financial_services.svg', 'zurich.svg', 'whitbread.svg', 'mears_group.svg', 'whirlpool.svg', 'firstsource_solutions_ltd.svg', 'suzuki.svg', 'legal_&_general_group.svg', 'metro_bank.svg', 'network_rail.svg', 'mtr_elizabeth_line.svg', 'sky.svg', 'new_day.svg', 'vitality.svg', 'st_james_s_place.svg', 'financial_services_compensation_scheme.svg', 'fujitsu.svg', 'knight_frank.svg', 'allied_irish_banks.svg', 'xln.svg', 'sage.svg']

    const scrollingLogos2 = ['volvo.svg', 'intuit_australia.svg', 'capital_one_logo.svg', 'trusted_housesitters.svg', 'kpmg.svg', 'accenture.svg', 'aldi_stores.svg', 'aviva.svg', 'axa_health.svg', 'business_stream.svg', 'cross_country_trains.svg', 'direct_line_group.svg', 'ee.svg', 'marks_spencer_bank.svg', 'mobile_mini.svg', 'samsic.svg', 'sgn.svg', 'the_aa.svg', 'unibail_rodamco_westfield.svg', 'vanarama.svg', 'virgin_media.svg', 'virgin_money.svg']

    return (
        <>
            <section className='pt-16 pb-12 xs:pt-12 xs:pb-8 xl:pt-12 xl:pb-8 2xl:pt-20 2xl:pb-4 bg-white'>
                <div className='items-center'>
                    <h1 className='text-center'>UK Customer Experience Awards</h1>
                    <h4 className='text-ai-gray-900 pt-5 font-bold'>Love your customer, love yourself</h4>
                    <p className='text-ai-gray-900 pt-3'>Wembley Stadium, London, 16th October 2025</p>
                    <div className='flex flex-1 gap-5 items-center max-md:flex-col'>
                    <button>
                        <Link to={`https://awintern.com/registration?program=65&type=ENTRANT`} target='_blank'>
                            <div>Enter Now</div>          
                        </Link>
                    </button>
                    </div>
                </div>
            </section>

            <section className='bg-white'>
                <div className='max-w-[100vw]'>
                    <Slider props={{
                        slidesPerView: 'auto',
                        centeredSlides: true,
                        autoplay: true,
                        loop: true,
                        className: 'max-w-[100%]',
                        slideWrapperClassName: 'max-w-[100%] xl:max-w-[1128px] 2xl:max-w-[1290px] px-0 2xl:px-0',
                        slideClassName: 'rounded-3xl'
                    }}>
                        <img src='./images/slider/cxa/UKCXA24-ceremony-5.png' alt='' />
                        <img src='./images/slider/cxa/UKCXA24-judge-ceremony.png' alt='' />
                        <img src='./images/slider/cxa/UKCXA24-red-carpet.png' alt='' />
                        <img src='./images/slider/cxa/Overall-Winner.png' alt='' />
                    </Slider>
                </div>
            </section>

            
            <section className="bg-gray-100 text-ai-gray-900">
                <div>
                    <h2 className="bg-gradient-analagous-rose-triad-orange self-start reveal top">Our Story</h2>
                    <h4 className='reveal top'>The first, the biggest and fairest awards celebrating CX, in the world today.</h4>
                    <div className="gap-8 pt-8">
                        <p className="reveal top">Born out of a commitment to recognise hard work, UKCXA™ honours business and professional achievements. It has grown into the most respected awards programme in the sector.</p>
                        <p className='reveal top'>With a transparent judging process and a commitment to fairness, it sets the standard for CX excellence. The program recognises outstanding customer experience performance across the UK.</p>
                        <p className="reveal top delay-300">Since its first edition in 2010, UKCXA™ has evolved beyond an awards event into a leading hub for CX excellence. It brings together diverse leaders, innovative thinkers, and pioneering companies to spark new ideas and build valuable connections.</p>
                        <p className="reveal top delay-300">By celebrating CX as a driver of success, UKCXA™ continues to shape industry leadership. It inspires excellence and strengthens the future of customer experience.</p>
                        <button className='dark self-start reveal top mt-5'>
                            <Link to={`/how-to-enter`}>
                                <div>About the Awards</div>
                            </Link>
                        </button>
                    </div>
                </div>
            </section>

            <section className='pb-16 2xl:pb-24 bg-white'>
                <div>
                    <h2 className='winners-circle-title reveal top relative'>We are proud of<span><img src={miniLogo} alt='logo' /></span></h2>
                    <p className='reveal top text-ai-gray-900'>Over the years, the <b>UKCXA™</b> has evolved, bringing together diverse leaders, innovative thinkers, and pioneering companies to share knowledge and foster growth. With a unique judging process and a focus on both recognition and development, we continue to champion customer experience as a driver of business success.</p> 
                    <div className=''>
                        <div className='flex flex-1 justify-center items-start gap-5 max-md:flex-col'>
                            <div className='w-full p-5 max-lg:w-full'>
                                <Stats props={{ className: 'reveal top delay-300' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-gradient-pastel">
                <div>
                    <h2 className="bg-gradient-analagous-rose-triad-orange self-start reveal top">Previous Winners and Finalists</h2>
                </div>

                <ImageScroller props={{ folder: './images/scrolling-logos/', images: scrollingLogos, reverse: false }}/>
                <ImageScroller props={{ folder: './images/scrolling-logos/', images: scrollingLogos1, reverse: true }}/>
                <ImageScroller props={{ folder: './images/scrolling-logos/', images: scrollingLogos2, reverse: false }}/>

            </section>

            <section className='bg-white text-ai-gray-900'>
                <div>
                    <h2 className="bg-gradient-analagous-rose-triad-orange self-start reveal top">What sets us apart</h2>
                    <p className='reveal top'>At the UK Customer Experience Awards™, we take pride in our robust and transparent judging process, ensuring that every participant is evaluated with fairness and integrity.</p>
                    <p className='reveal top'>Our carefully designed entry and scoring system recognises your achievements in delivering exceptional customer experiences, regardless of your company’s size. The process is rigorous yet rewarding, offering unmatched credibility to your accomplishments.</p>
                </div>

                <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-8 mt-6'>
                    <div>
                        <p className='reveal top font-bold'>How It Works:</p>
                        <ul className='list'>
                            <li className='reveal top'><span className='font-bold'>Two Scoring Phases:</span> Entries are evaluated through written submissions and live presentations, each scored independently by dedicated judging panels.</li>
                            <li className='reveal top'><span className='font-bold'>Dedicated Judges:</span> To ensure quality, each judge spends an average of 6.5 hours assessing entries, with a maximum of 8 entries per judge.</li>
                        </ul>
                    </div>
                    <div>
                    <p className='reveal top delay-300'>This meticulous approach allows us to provide:</p>
                        <ul className='list'>
                            <li className='reveal top delay-300'><span className='font-bold'>Benchmark Reports for Finalists:</span> Detailed insights, including judges’ scores and written feedback, to help you improve and grow.</li>
                            <li className='reveal top delay-300'><span className='font-bold'>Feedback for Judges:</span> A comprehensive report reflecting their results and comments, reinforcing their professionalism and behaviour.</li>
                        </ul>
                    </div>
                </div>
                

                <div>
                    <p className='reveal top'>Our process doesn’t just reward excellence-it sets a standard for fairness and fosters growth for all participants. This commitment makes the UKCXA™ the most trusted and transparent awards program in the industry.</p>

                    <button className='self-start reveal top'>
                        <Link to={`/plan2win`}>
                            <div>Plan2Win</div>
                        </Link>
                    </button>
                </div>
            </section>

            <section className='bg-gradient-pastel'>
                <div className='relative z-[8]'>
                    <div className='h-1/2'>
                        <div className='container mx-auto h-full grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-0 auto-rows-min'>
                            <div className='flex lg:col-span-2'>
                                <h2 className='self-start reveal top'>Customer <br /> Experience Magazine</h2>
                            </div>
                            <div>
                                <h4 className='text-ai-gray-900 reveal top'><Link to='https://cxm.co.uk' target='_blank' rel="noopener noreferrer" className='mailto'>CXM®</Link> is a leading publication dedicated to aligning CX practice, business and technology.</h4>
                                <div className='reveal top'>
                                    <button className='dark'>
                                        <Link to='https://cxm.co.uk' target='_blank'>
                                            <div>Explore CXM®</div>
                                        </Link>
                                    </button>
                                </div>
                            </div>
                            <p className='reveal top delay-300 text-ai-gray-900'>As part of the UKCXA, participants have the unique opportunity to gain exposure in CXM®, sharing their success stories with a wider audience. The magazine is an add on for all participants to CXAs and provides invaluable recognition and an avenue to further elevate your achievements within the CX industry.</p>
                        </div>
                    </div>
                </div>
            </section>

            
            <section className='pb-0 border-t border-t-ai-gray-800'>
                <Footer />
            </section>
        </>
    )
}

const Home = () => {
    
    return (
        <Main />
    )
}   

export default Home